/* Connection Font Icons */
@font-face {
    font-family: 'FontAwesome';
    src: url('../fonts/fontawesome-webfont.eot');
    src: url('../fonts/fontawesome-webfont.eot') format('embedded-opentype'), 
    url('../fonts/fontawesome-webfont.woff2') format('woff2'), 
    url('../fonts/fontawesome-webfont.woff') format('woff'), 
    url('../fonts/fontawesome-webfont.ttf') format('truetype'), 
    url('../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
    font-weight: 400;
    font-style: normal
}
/* Connection Font Icons */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 2, 2020 */
@font-face {
    font-family: 'bernier_distressedregular';
    src: url('../fonts/BERNIERDistressed-Regular.eot');
    src: url('../fonts/BERNIERDistressed-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/BERNIERDistressed-Regular.woff2') format('woff2'),
         url('../fonts/BERNIERDistressed-Regular.woff') format('woff'),
         url('../fonts/BERNIERDistressed-Regular.ttf') format('truetype'),
         url('../fonts/BERNIERDistressed-Regular.svg#bernier_distressedregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bernier_regularregular';
    src: url('../fonts/BERNIERRegular-Regular.eot');
    src: url('../fonts/BERNIERRegular-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/BERNIERRegular-Regular.woff2') format('woff2'),
         url('../fonts/BERNIERRegular-Regular.woff') format('woff'),
         url('../fonts/BERNIERRegular-Regular.ttf') format('truetype'),
         url('../fonts/BERNIERRegular-Regular.svg#bernier_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'bernier_shaderegular';
    src: url('../fonts/BERNIERShade-Regular.eot');
    src: url('../fonts/BERNIERShade-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/BERNIERShade-Regular.woff2') format('woff2'),
         url('../fonts/BERNIERShade-Regular.woff') format('woff'),
         url('../fonts/BERNIERShade-Regular.ttf') format('truetype'),
         url('../fonts/BERNIERShade-Regular.svg#bernier_shaderegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'violetregular';
    src: url('../fonts/violet-webfont.eot');
    src: url('../fonts/violet-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/violet-webfont.woff2') format('woff2'),
         url('../fonts/violet-webfont.woff') format('woff'),
         url('../fonts/violet-webfont.ttf') format('truetype'),
         url('../fonts/violet-webfont.svg#violetregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Light';
	src: url('../fonts/DINPro-Light.eot');
	src: local('☺'), 
	    url('../fonts/DINPro-Light.woff') format('woff'), 
		url('../fonts/DINPro-Light.ttf') format('truetype'), 
		url('../fonts/DINPro-Light.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_DINPro-Black';
	src: url('../fonts/DINPro-Black.eot');
	src: local('☺'), 
		url('../fonts/DINPro-Black.woff') format('woff'), 
		url('../fonts/DINPro-Black.ttf') format('truetype'), 
		url('../fonts/DINPro-Black.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Italic';
	src: url('../fonts/DINPro-Italic.eot');
	src: local('☺'), 
		url('../fonts/DINPro-Italic.woff') format('woff'), 
		url('../fonts/DINPro-Italic.ttf') format('truetype'), 
		url('../fonts/DINPro-Italic.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Medium';
	src: url('../fonts/DINPro-Medium.eot');
	src: local('☺'), 
		url('../fonts/DINPro-Medium.woff') format('woff'), 
		url('../fonts/DINPro-Medium.ttf') format('truetype'), 
		url('../fonts/DINPro-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_DINPro-Bold';
	src: url('../fonts/DINPro-Bold.eot');
	src: local('☺'), 
		url('../fonts/DINPro-Bold.woff') format('woff'), 
		url('../fonts/DINPro-Bold.ttf') format('truetype'), 
		url('../fonts/DINPro-Bold.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'Conv_DINPro';
	src: url('../fonts/DINPro.eot');
	src: local('☺'), 
		url('../fonts/DINPro.woff') format('woff'), 
		url('../fonts/DINPro.ttf') format('truetype'), 
		url('../fonts/DINPro.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 17, 2020 */
@font-face {
    font-family: 'latobold';
    src: url('../fonts/lato-bold-webfont.eot');
    src: url('../fonts/lato-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-bold-webfont.woff2') format('woff2'),
         url('../fonts/lato-bold-webfont.woff') format('woff'),
         url('../fonts/lato-bold-webfont.ttf') format('truetype'),
         url('../fonts/lato-bold-webfont.svg#latobold') format('svg');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'latoitalic';
    src: url('../fonts/lato-italic-webfont.eot');
    src: url('../fonts/lato-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-italic-webfont.woff2') format('woff2'),
         url('../fonts/lato-italic-webfont.woff') format('woff'),
         url('../fonts/lato-italic-webfont.ttf') format('truetype'),
         url('../fonts/lato-italic-webfont.svg#latoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'latomedium';
    src: url('../fonts/lato-medium-webfont.eot');
    src: url('../fonts/lato-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-medium-webfont.woff2') format('woff2'),
         url('../fonts/lato-medium-webfont.woff') format('woff'),
         url('../fonts/lato-medium-webfont.ttf') format('truetype'),
         url('../fonts/lato-medium-webfont.svg#latomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'latoregular';
    src: url('../fonts/lato-regular-webfont.eot');
    src: url('../fonts/lato-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-regular-webfont.woff2') format('woff2'),
         url('../fonts/lato-regular-webfont.woff') format('woff'),
         url('../fonts/lato-regular-webfont.ttf') format('truetype'),
         url('../fonts/lato-regular-webfont.svg#latoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'latosemibold';
    src: url('../fonts/lato-semibold-webfont.eot');
    src: url('../fonts/lato-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-semibold-webfont.woff2') format('woff2'),
         url('../fonts/lato-semibold-webfont.woff') format('woff'),
         url('../fonts/lato-semibold-webfont.ttf') format('truetype'),
         url('../fonts/lato-semibold-webfont.svg#latosemibold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'latoheavy';
    src: url('../fonts/lato-heavy-webfont.eot');
    src: url('../fonts/lato-heavy-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/lato-heavy-webfont.woff2') format('woff2'),
         url('../fonts/lato-heavy-webfont.woff') format('woff'),
         url('../fonts/lato-heavy-webfont.ttf') format('truetype'),
         url('../fonts/lato-heavy-webfont.svg#latoheavy') format('svg');
    font-weight: normal;
    font-style: normal;
}

$regular: 'Conv_DINPro';
$regularBDR: 'bernier_distressedregular';
$regularBR: 'bernier_regularregular';
$regularBS: 'bernier_shaderegular';
$regularV: 'violetregular';
$bold: 'Conv_DINPro-Bold';
$black: 'Conv_DINPro-Black';
$light: 'Conv_DINPro-Light';
$italic: 'Conv_DINPro-Italic';
$medium: 'Conv_DINPro-Medium';
$boldSBL: 'latosemibold';
$italicL: 'latoitalic';
$mediumL: 'latomedium';
$regularL: 'latoregular';
$boldL:'latobold';
$heavyL: 'latoheavy';
@mixin inline-block {
	display: inline-block;
	vertical-align: top;
}
@mixin ul-default {
	list-style: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

*:after, *:before {
	display: block;
	line-height: 1;
}

[data-src] {
	opacity: 0;
	&[src] {
		opacity: 1;
		transition-duration: 400ms;
		transition-delay: 0.2s;
	}
}

.preload {
	background-color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 999;
	pointer-events: none;
	&.load {
		opacity: 0;
		transition: 500ms;
	}
	&.loadR {
		opacity: 1;
		transition: 500ms;
	}
	// .sk-double-bounce {
	// 	width: 80px;
	// 	height: 80px;
	// 	position: absolute;
	// 	top: 50%;
	// 	left: 0;
	// 	right: 0;
	// 	transform: translateY(-50%);
	// 	margin: auto;
	// 	.sk-child {
	// 	    width: 100%;
	// 	    height: 100%;
	// 	    border-radius: 50%;
	// 	    background-color: #099;
	// 	    opacity: 0.6;
	// 	    position: absolute;
	// 	    top: 0;
	// 	    left: 0;
	// 	    animation: sk-double-bounce 2.0s infinite ease-in-out;
	// 	}
	//   	.sk-double-bounce-2 {
	//     	animation-delay: -1.0s;
	//   	}
	// }
}
@keyframes sk-double-bounce {
    0%, 100% {
    	transform: scale(0);
  	}
  	50% {
    	transform: scale(1.0);
  	}
}

body {
	display: flex;
	height: 100vh;
    min-width: 300px;
	position: relative;
	flex-direction: column;
	font-family: $regular;
	color: #333333;
	-webkit-text-size-adjust: 100%;
	padding-top: 130px;
	margin: 0;
	&.welcome {
		min-width: 1000px;
		padding: 0;
	}
    @media screen and (max-width: 1024px) {
		padding-top: 100px;
	}
}

h1 {
	font-family: $regularBDR;
	font-size: 48px;
	text-transform: uppercase;
	font-weight: normal;
	text-align: center;
	color: #171717;
	line-height: 1;
	letter-spacing: 2px;
	margin: 0 0 25px;
	a {
		color:#171717;
		&:hover {
			color: #c39570;
		}
	}
	@media screen and (max-width: 1440px) {
		font-size: 42px;
	}
	@media screen and (max-width: 1200px) {
		font-size: 32px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 28px;
        margin-bottom: 30px;
	}
    @media screen and (max-width: 768px) {
        font-size: 24px;
        margin-bottom: 20px;
	}
	@media screen and (max-width: 600px) {
		font-size: 22px;
	}
}
h2 {
	font-family: $regularBDR;
	font-size: 48px;
	font-weight: normal;
	color: #171717;
	margin: 0 0 25px;
	a {
		color:#171717;
		&:hover {
			color: #c39570!important;
		}
	}
	@media screen and (max-width: 1440px) {
		font-size: 42px;
	}
	@media screen and (max-width: 1200px) {
		font-size: 30px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
        margin-bottom: 30px;
	}
    @media screen and (max-width: 768px) {
        font-size: 22px;
        margin-bottom: 20px;
	}
	@media screen and (max-width: 600px) {
		font-size: 20px;
	}
}
h3 {
	font-family: $regularV;
	font-size: 30px;
	font-weight: normal;
	color: #333333;
	@media screen and (max-width: 1440px) {
		font-size: 26px;
	}
	@media screen and (max-width: 1200px) {
		font-size: 22px;
	}
    
}
h4 {
	font-family: $medium;
	font-size: 22px;
	font-weight: normal;
	color: #333333;
    @media screen and (max-width: 1200px) {
        font-size: 20px;
    }
    @media screen and (max-width: 1024px) {
        font-size: 18px;
	}
	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
}
h5 {
	font-family: $medium;
	font-size: 18px;
	font-weight: normal;
	color: #333333;
	@media screen and (max-width: 1024px) {
		font-size: 16px;
	}
}
h6 {
	font-family: $medium;
	font-size: 16px;
	font-weight: normal;
	text-transform: uppercase;
	color: #333333;
}
p {
    margin: 0 0 15px;
}
a {
    color: #333333;
	transition: 300ms;
	text-decoration: none;
	&:focus {
		color: #333333;
		text-decoration: none;
	}
	&:hover {
		text-decoration: none;
        color: #c39570;
	}
}
textarea, 
button, 
select, 
input[type="text"], 
input[type="email"], 
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
	border-radius: 0px;
	-webkit-appearance: none;
}
.wrapper, .page {
	position: relative;
	flex: 1 0 auto;
	textarea, 
    input[type="text"], 
    input[type="email"], 
    input[type="search"], 
    input[type="password"] {
        min-height: 52px;
        width: 100%;
		background-color: #fff;
		font-family: $regular;
		font-size: 16px;
		padding: 15px 25px;
        margin-bottom: 15px;
		box-shadow: none;
		border: 1px solid #d0ab8a;
		border-radius: 4px;
		&::placeholder {
			color: #a3a3a3;
		}
	}
	@media screen and (max-width: 1024px) {
		textarea, 
		input[type="text"], 
		input[type="email"], 
		input[type="search"], 
		input[type="password"] {
			min-height: 45px;
			padding: 11px 15px;
		}
	}
}
.wrapper {
	.container {
		padding-top: 100px;
		padding-bottom: 100px;
		@media screen and (max-width: 1440px) {
			padding-top: 75px;
			padding-bottom: 75px;
		}
		@media screen and (max-width: 1280px) {
			padding-top: 50px;
			padding-bottom: 50px;
		}
		@media screen and (max-width: 1024px) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@media screen and (max-width: 768px) {
			padding-top: 25px;
			padding-bottom: 25px;
		}
	}
	h3.beige {
		margin-top: -30px;
	}
	@media screen and (max-width: 1440px) {
		h3.beige {
			margin-top: -20px;
		}
	}
	@media screen and (max-width: 1024px) {
		h3.beige {
			margin-bottom: 15px;
		}
	}
}

.container {
    width: 1170px;
    padding: 0 15px;
	margin: 0 auto;
	@media screen and (max-width: 1200px) {
		width: 100%;
	}
}

.close {
	display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 8px;
    top: 10px;
    opacity: 1;
    outline: none;
    &:before, &:after {
        content: '';
        width: 20px;
        height: 2px;
        background-color: #c39570;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: -1px auto 0;
        transition: 300ms;
    }
    &:before {
        transform: rotate(45deg);
    }
    &:after {
        transform: rotate(-45deg);
    }
    &:hover {
        &:before {
            transform: rotate(135deg);
        }
        &:after {
            transform: rotate(45deg);
        }
    }
}
.btn {
	display: table;
	height: 50px;
	width: auto!important;
	background-color: transparent!important;
	font-family: $medium!important;
	font-size: 16px!important;
	color: #c39570!important;
	text-align: center;
	text-transform: uppercase;
	cursor: pointer;
	border-radius: 3px!important;
	border: 2px solid #e1cab7;
	padding: 15px 35px!important;
	line-height: 1;
	position: relative;
	transition: 300ms;
	&:focus, &:active {
		background-color: transparent!important;
		color: #fff;
	}
	&:hover, &.active {
		background-color: #c39570!important;
		border: 2px solid #c39570;
		color: #fff!important;
	}	
	&.btn--w {
		border: 2px solid rgba(255,255,255,0.7);
		color: #fff!important;
		&:hover {
			border: 2px solid #c39570!important;
		}
	}
	&.btn--br {
		background-color: #c39570!important;
		color: #fff!important;
		border: 2px solid #c39570!important;
		&:hover {
			background-color: #fff!important;
			color: #c39570!important;
		}
	}
	&.more {
		padding-left: 70px!important;
		padding-right: 90px!important;
		margin: 45px auto 0;
		&:after, &:before {
			content: '\f105';
			font-family: 'fontAwesome';
			font-size: 17px;
			position: absolute;
			top: 46%;
			right: 76px;
			transform: translateY(-50%) rotate(0deg);
			transition: 300ms;
		}
		&:after {
			right: 70px;
		}
		&:hover {
			&:before {
				right: 72px;
			}
			&:after {
				right: 64px;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		&.more {
			margin-top: 30px;
		}
	}
	@media screen and (max-width: 1024px) {
		height: 45px;
		font-size: 14px!important;
		padding: 13px 25px 12px!important;
		&.more {
			padding-left: 30px!important;
			padding-right: 50px!important;
			margin-top: 20px;
			&:before, &:after {
				font-size: 15px;
			}
			&:before {
				right: 38px!important;
			}
			&:after {
				right: 30px!important;
			}
		}
	}
	@media screen and (max-width: 768px) {
		height: 40px;
		padding: 10px 20px 9px!important;
		&.more {
			margin-top: 15px;
			&:after, &:before {
				top: 50%;
			}
		}
	}
}
a.btn {
	@media screen and (max-width: 768px) {
		padding-top: 11px!important;
	}
}

.check--list {
	@include ul-default;
	li {
		display: block;
		position: relative;
		padding-left: 25px;
		margin-bottom: 5px;
		&:before {
			content: '\f00c';
			position: absolute;
			font-family: 'fontAwesome';
			font-size: 18px;
			color: #000;
			left: 0;
			top: 0px;
		}
	}
}

.col--location,
.col--phone,
.col--email,
.col--time {
	position: relative;
	padding-left: 20px;
	&:after {
		content: '';
		font-family: 'fontAwesome';
		font-size: 18px;
		color: #937258;
		position: absolute;
		top: 0px;
		left: 0;
	}
}
.col--location:after {
	content: '\f041';
	font-size: 16px;
	top: 0px;
}
.col--phone {
	&:after {
		content: '\f095';
		font-size: 16px;
	}
	a {
		display: table;
		margin-bottom: 4px;
	}
}
.col--email {
	&:after {
		content: '\f0e0';
		font-size: 13px;
		top: 4px;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
.col--time:after {
	content: '\f017';
	font-size: 14px;
}

.title--txt {
	display: table;
	padding: 0 40px;
	margin: 0 auto 35px;
	position: relative;
	&:before, &:after {
		content: '';
		height: 6px;
		width: 27px;
		background-color: #171717;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
	&:before {
		left: 0;
	}
	&:after {
		right: 0;
	}
	&.title--left {
		padding-left: 0;
		margin-left: 0;
		margin-right: 0;
		&:before {
			display: none;
		}
	}
	&.wh {
		color: #fff;
		&:before, &:after {
			background-color: #fff;
		}
	}
	@media screen and (max-width: 1440px) {
		padding: 0 30px;
		margin-bottom: 25px;
		&:before, &:after {
			width: 20px;
			height: 3px;
		}
	}
	@media screen and (max-width: 1024px) {
		padding: 0 25px;
		margin-bottom: 20px;
		&:before, &:after {
			width: 15px;
		}
	}
	@media screen and (max-width: 768px) {
		padding: 0 20px;
		&:before, &:after {
			width: 10px;
		}
	}
}
.beige {
	color: #c39570;
}
.center {
	text-align: center;
}

/* header */
header {
	background: url(../img/img-3.jpg) no-repeat center center;
	background-size: cover;
	font-size: 14px;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	line-height: 1;
	box-shadow: 0px 0px 20px #171717;
	z-index: 9;
	.container {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	> .container {
		height: 100px;
	}
	.up {
		background-color: #21201e;
		color: #a8a8a7;
		z-index: 2;
		position: relative;
		a {
			color: #bdbdbc;
			&:focus {
				color: #bdbdbc;
			}
			&:hover {
				color: #c39570;
			}
		}
		.container {
			height: 30px;
		}
	}
	.col--location {
		&:after {
			font-size: 14px;
		}
	}

	ul {
		@include ul-default;
	}

	.h--arr {
		font-family: 'fontAwesome';
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		&:after {
			content: '\f107';
		}
	}

	/* header phones */
	.phones {
		position: relative;
		white-space: nowrap;
		padding-right: 15px;
		&:after {
			font-size: 15px;
			top: -1px;
		}
		a {
			margin-bottom: 0;
		}
		ul {
			display: none;
			background-color: #21201e;
			position: absolute;
			left: 0;
			top: calc(100% + 6px);
			padding: 5px 15px 10px 20px;
			li {
				display: block;
				margin: 5px 0;
			}
		}
		.close {
			display: none;
		}
	}
	/* header phones */

	/* header logo */
	.logo {
		width: 124px;
		img {
			width: 100%;
		}
	}
	/* header logo */

	/* header nav */
	.nav {
		font-size: 16px;
		> ul {
			display: flex;
			> li {
				display: flex;
				height: 100px;
				align-items: center;
				margin: 0 22px;
				> a {
					font-family: $medium;
					color: #171717;
					padding: 10px 0;
					text-transform: uppercase;
					border-bottom: 2px solid transparent;
					&:hover {
						border-bottom: 2px solid #c39570;
					}
				}
				.nav__block {
					display: none;
					width: 1140px;
					background-color: #171717;
					position: absolute;
					top: 100%;
					left: 0;
					right: 0;
					margin: 0 auto;
					box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
					.nav__blocks {
						display: flex;
					}
					.nav__item {
						@include inline-block;
						width: calc(16.66% - 5px);
						font-family: $medium;
						text-align: center;
						color: #fff;
						padding: 30px 15px;
						&:first-of-type {
							width: calc(16.66% + 10px);
							padding-left: 30px;
						}
						&:last-of-type {
							width: calc(16.66% + 10px);
							padding-right: 30px;
						}
						.nav__img {
							height: 155px;
							width: 100%;
							overflow: hidden;
							margin-bottom: 15px;
							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center center;
								transition: 300ms;
								transition-delay: 0.15s;
							}
						}
						&:hover, &.active {
							background-color: #2e2e2e;
							color: #c39570;
							.nav__img {
								img {
									transform: scale(1.1);
								}
							}
						}
						&.active {
							color: #c39570;
						}
					}
				}
				&.active > a {
					border-bottom: 2px solid #c39570;
				}
			}
		}
	}
	/* header nav */

	/* header cart */
	.cart {
		font-size: 16px;
		color: #171717;
		position: relative;
		padding-left: 45px;
		&:after {
			content: '';
			width: 26px;
			height: 26px;
			background: url(../img/img-2.png) no-repeat center center;
			background-size: contain;
			position: absolute;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
		}
		.cart__qt {
			display: flex;
			background-color: #fff;
			color: #21201e;
			width: 18px;
			height: 18px;
			font-size: 12px;
			border-radius: 100%;
			align-items: center;
			justify-content: center;
			text-align: center;
			position: absolute;
			top: 40%;
			left: 15px;
			transform: translateY(-70%);
			z-index: 1;
		}
		&:hover {
			color: #171717;
		}
	}
	/* header cart */
	
	@media screen and (max-width: 1024px) {
		.up {
			.container {
				height: 25px;
			}
		}
		> .container {
			justify-content: center;
			height: 75px;
		}
		.logo {
			width: 100px;

		}
		.cart {
			position: absolute;
			right: 0px;
			top: 60px;
			.cart__sum {
				display: none;
			}
		}
		.nav {
			display: none;
			background: url(../img/img-3.jpg) repeat;
			position: fixed;
			max-height: calc(100% - 100px);
			top: 100px;
			left: 0;
			right: 0;
			overflow: auto;
			padding: 10px 15px;
			z-index: 1;
			border-top: 2px solid #21201e;
			border-bottom: 2px solid #21201e;
			> ul {
				flex-direction: column;
				> li {
					flex-direction: column;
					height: auto;
					margin: 0;
					.nav__block {
						width: 100%;
						position: relative;
						top: 0;
						left: 0;
						right: 0;
						margin-top: 10px;
						.nav__blocks {
							flex-wrap: wrap;
							justify-content: center;
							padding: 7.5px;
						}
						.nav__item {
							width: 25%;
							padding: 10px 7.5px;
							&:first-of-type {
								width: 25%;
								padding: 10px 7.5px;
							}
							&:last-of-type {
								width: 25%;
								padding: 10px 7.5px;
							}
						}
					}
					> a {
						padding: 8px 0;
					}
				}
			}
		}
		.nav--btn {
			width: 35px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 45px;
			padding: 0;
			margin: 0;
			outline: none;
			float: none;
			z-index: 1;
			span {
				position: absolute;
				left: 0;
				right: 0;
				width: 85%;
				height: 3px;
				margin: 0 auto;
				background-color: #21201e;
				transition: 350ms;
				&:nth-of-type(1) {
					top: 7px;
				}
				&:nth-of-type(2) {
					top: 14px;
				}
				&:nth-of-type(3) {
					top: 21px;
				}
			}
			&.open {
				span {
					&:nth-of-type(1) {
						top: 14px;
						transform: rotate(45deg);
					}
					&:nth-of-type(2) {
						opacity: 0;
					}
					&:nth-of-type(3) {
						top: 14px;
						transform: rotate(-45deg);
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.nav {
			> ul > li {
				.nav__block {
					.nav__item {
						width: 33.33%!important;
					}
				}
			}
		}
	}
	@media screen and (max-width: 600px) {
		.nav {
			> ul > li {
				.nav__block {
					.nav__item {
						width: 50%!important;
						.nav__img {
							height: 125px;
							margin-bottom: 10px;
						}
						.nav__title {
							font-size: 14px;
						}
					}
				}
			}
		}
		.phones {
			display: none;
			background-color: #21201e;
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			padding: 15px;
			font-size: 16px;
			z-index: 2;
			&:after {
				display: none;
			}
			.close {
				display: block;
				position: absolute;
				right: 5px;
				top: 5px;
			}
			a {
				margin: auto;
			}
			ul {
				display: block;
				position: relative;
				top: 0;
				left: 0;
				right: 0;
				transform: translate(0);
				padding: 0;
				margin: 0;
				li {
					margin: 7px 0;
					&:last-of-type {
						margin-bottom: 0;
					}
				}
			}
			.h--arr {
				display: none;
			}
		}
		.phones--btn {
			position: absolute;
			top: 52px;
			right: 55px;
			font-family: 'fontAwesome';
			font-size: 20px;
			color: #21201e;
			text-decoration: none!important;
			&:after {
				content: '\f095';
				font-family: 'fontAwesome';
			}
		}
	}
	@media screen and (max-width: 480px) {
		.up {
			font-size: 12px;
			.container {
				padding: 0 5px;
			}
		}
		.col--location, .col--time {
			padding-left: 13px;
			&:after {
				font-size: 10px;
				top: 1px;
			}
		}
	}
}
/* header */