/* main content */
.modal-open {
	overflow-y: visible;
	.modal {
		overflow: auto;
	}
}

.fancybox-enabled {
	overflow: visible;
	body {
		overflow-x: hidden;
	}
}

/* main slider */
.slider {
	margin-bottom: 0px!important;
	.slick-slide {
		height: calc(100vh - 130px);
		overflow: hidden;
		position: relative;
		outline: none;
		&:after {
			content: '';
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.4);
			position: absolute;
			top: 0;
			left: 0;
		}
		img {
			display: block;
			width: 100%;
			height: auto;
		}
		.slider__block {
			position: absolute;
			left: 0;
			right: 0;
			top: 45%;
			text-align: center;
			font-size: 18px;
			color: #fff;
            margin: 0 auto;
            transform: translateY(-50%);
			z-index: 1;
			.slider__title {
				font-family: $regularBDR;
				font-size: 60px;
				margin-bottom: 10px;
			}
			.btn {
				margin: 50px auto 0;
			}
		}
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	&.dots {
		.slick-dots {
			bottom: 40px;
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-slide {
			.slider__block {
				.slider__title {
					font-size: 50px;
				}
				.btn {
					margin-top: 30px;
				}
			}
		}
		&.dots {
			.slick-dots {
				bottom: 20px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-slide {
			height: 400px;
			.slider__block {
				font-size: 16px;
				.slider__title {
					font-size: 40px;
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			height: 350px;
			.slider__block {
				padding: 0 10px;
				.slider__title {
					font-size: 30px;
					margin-bottom: 5px;
				}
				.btn {
					margin-top: 25px;
				}
			}
		}
		&.dots {
			.slick-dots {
				bottom: 10px;
			}
		}
	}
	@media screen and (max-width: 600px) {
		.slick-slide {
			height: 300px;
			.slider__block {
				.slider__title {
					font-size: 24px;
				}
				.btn {
					margin-top: 20px;
				}
			}
		}
	}
}
.dots {
	margin-bottom: 0!important;
	.slick-dots {
		display: flex;
		justify-content: center;
		bottom: 30px;
		li {
			margin: 0px 8px;
			button {
				display: flex;
				width: 22px;
				height: 22px;
				align-items: center;
				justify-content: center;
				background-color: transparent;
				padding: 0;
				border-radius: 100%;
				border: 1px solid transparent;
                transition: 300ms;
				&:before {
					display: none;
				}
				&:after {
					content: '';
					width: 10px;
					height: 10px;
					background-color: #ad795b;
					border-radius: 100%;
					right: 0;
					margin: 0 auto;
				}
				&:hover {
					border: 1px solid #fff;
					&:after {
						background-color: #fff;
					}
				}
			}
			&.slick-active {
				button {
					border: 1px solid #fff;
					&:after {
						background-color: #fff;
					}
				}
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-dots {
			li {
				margin: 0 5px;
				button {
					width: 18px;
					height: 18px;
					&:after {
						width: 6px;
						height: 6px;
					}
				}
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-dots {
			li {
				width: auto;
				height: auto;
				margin: 0 3px;
				button {
					width: 15px;
					height: 15px;
					&:after {
						width: 5px;
						height: 5px;
					}
				}
			}
		}
	}
}
.arr {
	.slick-arrow {
		width: 74px;
		height: 24px;
		z-index: 1;
		&:before {
			display: none;
		}
		&:after {
			content: '';
			background: url(../img/prev.png) no-repeat center center;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}
		&.slick-prev {
			left: 60px;
		}
		&.slick-next {
			right: 60px;
			&:after {
				transform: rotate(-180deg);
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-arrow {
			width: 60px;
			height: 20px;
			&:after {
				background-size: contain;
				background-position: center center;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-arrow {
			width: 50px;
			height: 15px;
		}
	}
}
/* main slider */

/* main about */
.bg--dark {
	background: url(../img/img-11.jpg);
	color: #cccccc;
	h1 {
		color: #595959;
		&:after, &:before {
			background-color: #595959;
		}
	}
	h2 {
		color: #fff;
		a {
			color: #fff;
		}
	}
}
.about {
	position: relative;
	.container {
		padding: 110px 15px;
		position: relative;
		z-index: 1;
	}
	.bg--img {
		position: absolute;
		&.left {
			left: -50px;
			bottom: -150px;
		}
		&.right {
			right: 0;
			top: 35%;
		}
	}
	@media screen and (max-width: 1680px) {
		.container {
			padding: 80px 15px;
		}
	}
	@media screen and (max-width: 1440px) {
		.bg--img {
			&.left {
				width: 300px;
			}
			&.right {
				width: 35%;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.container {
			padding:  50px 15px;
		}
	}
	@media screen and (max-width: 1024px) {
		.container {
			padding: 30px 15px;
		}
		.bg--img {
			display: none;
		}
	}
}
.about__block {
	display: flex;
	.about__img {
		display: flex;
		background: url(../img/img-15.png) no-repeat center center;
		background-size: contain;
		height: 725px;
		width: calc(50% + 150px);
		justify-content: center;
		align-items: center;
		padding: 100px;
		margin-left: -165px;
		img {
			display: block;
			max-width: 100%;
		}
	}
	.right {
		width: 50%;
		padding: 100px 0 80px 15px;
	}
	p {
		margin-bottom: 18px;
	}
	.btn {
		margin-top: 60px;
	}
	@media screen and (max-width: 1440px) {
		.about__img {
			width: calc(50% + 50px);
			height: 580px;
			padding: 75px;
			margin-left: -120px;
		}
		.right {
			padding: 75px 0;
		}
		.btn {
			margin-top: 40px;
		}
	}
	@media screen and (max-width: 1200px) {
		.about__img {
			width: 50%;
			margin: 0;
		}
		.right {
			width: 50%;
		}
	}
	@media screen and (max-width: 1024px) {
		align-items: flex-start;
		.about__img {
			height: 450px;
			padding: 50px;
		}
		.right {
			padding: 50px 0;
		}
	}
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		padding-bottom: 30px;
		.about__img {
			width: 350px;
			height: 350px;
			padding: 30px;
			margin: 0 auto 10px;
		}
		.right {
			width: 100%;
			padding: 0;
		}
		.btn {
			margin-top: 20px;
		}
	}
	@media screen and (max-width: 380px) {
		.about__img {
			width: 300px;
			height: 300px;
		}
	}
}

.advantages {
	display: flex;
	justify-content: space-between;
	.advantages__item {
		max-width: 25%;
		text-align: center;
		padding: 0 15px;
		margin-bottom: 20px;
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
		.num {
			display: block;
			font-family: $regularBDR;
			font-size: 48px;
			color: #fff;
			line-height: 1;
			margin-bottom: 12px;
		}
		.txt {
			font-family: $regular;
			color: #c39570;
			text-transform: uppercase;
		}
	}
	@media screen and (max-width: 1200px) {
		.advantages__item {
			.num {
				font-size: 38px;
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.advantages__item {
			font-size: 14px;
		}
	}
	@media screen and (max-width: 768px) {
		flex-wrap: wrap;
		.advantages__item {
			max-width: 100%;
			width: 50%;
			font-size: 12px;
			padding: 0 7.5px!important;
		}
	}
}
/* main about */

/* main items */
.items__slider {
	margin: 0 -15px;
	.slick-track {
		display: flex;
		z-index: 1;
	}
	.slick-slide {
		height: auto;
		margin-bottom: 80px;
	}
	.slick-dots {
		bottom: 3px;
	}
	&.arr {
		.slick-arrow {
			top: auto;
			transform: translateY(0);
			bottom: 0;
			&.slick-prev {
				left: 15px;
			}
			&.slick-next {
				right: 15px;
			}
		}
	}
	&.hov {
		.slick-arrow {
			z-index: -1;
		}
		.slick-dots {
			z-index: -1;
		}
	}
	@media screen and (max-width: 1200px) {
		margin: 0 -10px;
		.item__img {
			height: 300px;
		}
		&.arr {
			.slick-arrow {
				&.slick-prev {
					left: 5px;
				}
				&.slick-next {
					right: 5px;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-slide {
			margin-bottom: 40px;
		}
		&.arr {
			.slick-arrow {
				bottom: 5px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		margin-left: -7.5px;
		margin-right: -7.5px;
		.slick-slide {
			margin: 0 7.5px 40px;
		}
		&.arr {
			.slick-arrow {
				bottom: 3px;
			}
		}
	}
	
}
.item {
	width: calc(33.33% - 30px);
	margin: 0 15px;
	outline: none;
	position: relative;
	z-index: 1;
	&:before {
		content: '';
		background: url(../img/img-41.jpg) no-repeat center center;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 360px;
		z-index: -1;
		border-radius: 0px 0px 5px 5px;
		transition: 300ms;
	}
	.item__img {
		display: block;
		height: 360px;
		overflow: hidden;
		border-radius: 5px 5px 0 0;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
	}
	.item__info {
		padding: 25px 30px 100px;
		border-radius: 0px 0px 5px 5px;
		a {
			display: block;
			font-family: $regularV;
			font-size: 30px;
			text-align: center;
		}
	}
	.item__qt {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: absolute;
		bottom: 30px;
		left: 30px;
		right: 30px;
	}
	.add {
		width: 195px!important;
		position: absolute;
		top: calc(100% - 50px);
		left: 0;
		right: 0;
		opacity: 0;
		margin: 0 auto;
	}
	&:hover {
		&:before {
			bottom: -80px;
		}
		.add {
			opacity: 1;
			top: 100%;
		}
	}
	@media screen and (max-width: 1440px) {
		.item__info {
			padding-bottom: 90px;
			a {
				font-size: 24px;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		margin: 0 10px;
		width: calc(33.33% - 20px);
	}
	@media screen and (max-width: 1024px) {
		&:before {
			top: 0!important;
			bottom: 0!important;
		}
		.item__info {
			padding: 20px 15px 150px;
		}
		.item__qt {
			left: 15px;
			right: 15px;
			bottom: 85px;
		}
		.add {
			width: 160px!important;
			top:auto!important;
			bottom: 25px!important;
			opacity: 1;
		}
	}
	@media screen and (max-width: 768px) {
		width: calc(50% - 15px);
		margin: 0 7.5px 15px;
	}
	@media screen and (max-width: 600px) {
		width: 100%;
		margin: 0 0 15px;
	}
	@media screen and (max-width: 375px) {
		.item__img {
			height: 300px;
		}
	}
}
.qt {
	display: flex;
	width: 152px;
	height: 46px;
	background-color: #f2f2f2;
	border-radius: 3px;
	padding: 8px 0;
	input[type="text"] {
		width: calc(100% - 96px);
		min-height: 30px;
		font-family: $regularV;
		font-size: 24px;
		text-align: center;
		color: #666666;
		background-color: #f2f2f2;
		border: none;
		border-left: 1px solid #d7d7d7;
		border-right: 1px solid #d7d7d7;
		padding: 5px;
		border-radius: 0px!important;
		margin: 0;
		outline: none;
		&::placeholder {
			color: #666666;
		}
	}
	.minus, .plus {
		width: 48px;
		position: relative;
		margin: 0!important;
		outline: none;
		&:before, &:after {
			content: '';
			width: 14px;
			height: 4px;
			background-color: #666666;
			position: absolute;
			top: 50%;
			left: 0;
			right: 0;
			margin: 0 auto;
			transform: translateY(-50%);
		}
	}
	.plus {
		&:after {
			transform: rotate(-90deg) translate(1px, 0);
		}
	}
	@media screen and (max-width: 1440px) {
		width: 125px;
		height: 42px;
		.minus, .plus {
			width: 40px;
			&:after {
				width: 12px;
				height: 2px;
			}
		}
		input[type="text"] {
			width: calc(100% - 80px);
			min-height: 26px;
			font-size: 20px;
		}
	}
	@media screen and (max-width: 1024px) {
		width: 100px;
		height: 40px;
		padding: 5px 0;
		.plus, .minus {
			width: 30px;
			&:after {
				width: 10px;
			}
			&:before {
				width: 10px;
			}
		}
		input[type="text"] {
			width: calc(100% - 60px);
		}
	}
}
.price {
	font-family: $regularV;
	font-size: 36px;
	color: #990000;
	.sm--price {
		font-family: $medium;
		font-size: 18px;
		color: #171717;
	}
	@media screen and (max-width: 1440px) {
		font-size: 30px;
	}
	@media screen and (max-width: 1024px) {
		font-size: 24px;
	}
}
.add {
	font-family: $regular!important;
	position: relative;
	padding-right: 60px!important;
	margin: 0 auto;	
	&:after {
		content: '';
		width: 18px;
		height: 18px;
		background: url(../img/img-42.png) no-repeat center center;
		background-size: contain;
		position: absolute;
		top: 50%;
		right: 35px;
		transform: translateY(-50%);
	}
	&:hover {
		&:after {
			background: url(../img/img-52.png) no-repeat center center;
			background-size: contain;
		}
	}
	@media screen and (max-width: 1024px) {
		padding-right: 50px!important;
		&:after {
			top: 45%;
			right: 22px;
		}
	}
}
/* main items */

/* main catalog */
.bg {
	position: relative;
	> img {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center center;
		z-index: -1;
	}
	
}
.radius {
	border-radius: 5px;
	overflow: hidden;
}
.cat__block {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px -30px;
	.cat__item {
		height: 290px;
		width: calc(33.33% - 30px);
		position: relative;
		margin: 0 15px 30px;
		&:before {
			content: '';
			position: absolute;
			background-color: #000;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0.2;
			transition: 300ms;
		}
		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			left: 0;
			bottom: 0;
			border-radius: 5px;
			border: 2px solid rgba(195, 149, 112, 0.7);
			transition: 300ms;
			opacity: 0;
		}
		&.h {
			height: 610px;
		}
		&.w {
			width: calc(66.66% - 30px);
		}
		.cat__title {
			font-family: $regularV;
			font-size: 36px;
			color: #fff;
			position: absolute;
			left: 15px;
			right: 15px;
			bottom: 25px;
			transition: 300ms;
			.title--txt {
				margin: 0 auto;
				&:after, &:before {
					background-color: #fff;
				}
			}
		}
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		&:hover {
			&:before {
				opacity: 0.5;
			}
			&:after {
				top: 10px;
				right: 10px;
				left: 10px;
				bottom: 10px;
				opacity: 1;
			}
			.cat__title {
				bottom: 50%;
				transform: translateY(50%);
			}
		}
	}
	.right {
		display: flex;
		justify-content: space-between;
		width: calc(66.66% - 30px);
		flex-wrap: wrap;
		margin: 0 15px;
		.cat__item {
			width: calc(50% - 15px);
			margin-left: 0;
			margin-right: 0;
			&.w {
				width: 100%;
				margin-left: 0;
				margin-right: 0;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.cat__item {
			width: calc(33.33% - 20px);
			margin: 0 10px 20px;
			&.w {
				width: calc(66.66% - 20px);
				margin: 0 10px 20px;
			}
		}
		.right {
			width: calc(66.66% - 20px);
			margin: 0 10px;
			.cat__item {
				width: calc(50% - 10px);
			}
		}
	}
	@media screen and (max-width: 1024px) {
		margin: 0 -7.5px;
		.cat__item {
			width: calc(33.33% - 15px);
			margin: 0 7.5px 15px;
			&.w {
				width: calc(66.66% - 15px);
				margin: 0 7.5px 15px;
			}
			&.h {
				height: 595px;
			}
		}
		.right {
			.cat__item {
				margin-bottom: 15px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.cat__item {
			height: 220px;
			&.h {
				height: 460px;
			}
			.cat__title {
				font-size: 26px;
			}
		}
	}
	@media screen and (max-width: 600px) {
		justify-content: center;
		margin-bottom: -15px;
		.cat__item {
			width: calc(50% - 15px);
			&.h {
				width: 100%;
			}
			&.w {
				width: 100%;
			}
		}
		.right {
			width: calc(100% - 15px);
			margin: 0 7.5px 15px;
			.cat__item {
				width: calc(50% - 7.5px);
				margin: 0 0 15px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
	@media screen and (max-width: 480px) {
		margin: 0 0 -15px;
		.cat__item {
			width: 100%;
			margin: 0 0 15px;
			&.w {
				margin: 0 0 15px;
			}
		}
		.right {
			width: 100%;
			margin: 0 0 15px;
			.cat__item {
				width: 100%;
				&.w {
					width: 100%;
				}
			}
		}
	}
}
/* main catalog */

/* main reviews */
.bg--layer {
	&:before {
		content: '';
		background-color: rgba(0,0,0,0.5);
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
	}
	> img {
		z-index: -2;
	}
}
.reviews {
	padding-bottom: 90px;
	.container {
		padding-bottom: 0;
	}
	.title--txt a {
		color: #fff;
	}
	.beige {
		margin-bottom: 45px;
	}
	@media screen and (max-width: 1440px) {
		padding-bottom: 75px;
		.beige {
			margin-bottom: 30px;
		}
	}
	@media screen and (max-width: 1200px) {
		padding-bottom: 50px;
	}
	@media screen and (max-width: 1024px) {
		padding-bottom: 30px;
		.beige {
			margin-bottom: 20px;
		}
	}
}
.reviews__slider {
	padding-bottom: 60px;
	.slick-list {
		padding: 0 28%!important;
	}
	.slick-track {
		display: flex;
	}
	.reviews__item {
		height: auto;
		background-color: #fff;
		color: #333333;
		outline: none;
		padding: 20px 40px 10px;
		margin: 0 15px;
		opacity: 0.7;
		.reviews__port {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 10px;
		}
		.reviews__img {
			width: 90px;
			height: 90px;
			overflow: hidden;
			border-radius: 100%;
			img {
				width: 100%;
				height: 100%;
				object-fit: contain;
				object-position: center center;
			}
		}
		.reviews__right {
			width: calc(100% - 90px);
			padding-left: 20px;
			color: #999999;
			padding-top: 15px;
		}
		h3 {
			margin: 0 0 10px;
		}
		&.slick-center {
			opacity: 1;
		}
	}
	&.dots {
		.slick-dots {
			bottom: 0;
		}
	}
	&.arr {
		.slick-arrow {
			top: auto;
			bottom: 0;
			transform: translateY(0);
			&.slick-prev {
				left: 50%;
				margin-left: -570px;
			}
			&.slick-next {
				right: 50%;
				margin-right: -570px;
			}
		}
	}
	@media screen and (max-width: 1680px) {
		.slick-list {
			padding: 0 22%!important;
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-list {
			padding: 0 20%!important;
		}
		.reviews__item {
			margin: 0 10px;
		}
	}
	@media screen and (max-width: 1366px) {
		.slick-list { 
			padding: 0 15%!important;
		}
	}
	@media screen and (max-width: 1200px) {
		padding-bottom: 40px;
		.slick-list {
			padding: 0 10%!important;
		}
		&.arr {
			.slick-arrow {
				&.slick-prev {
					left: 15px;
					margin: 0;
				}
				&.slick-next {
					right: 15px;
					margin: 0;
				}
			}
		}
	}
	@media screen and (max-width: 1024px) {
		.slick-list {
			padding: 0!important;
		}
		.slick-slide {
			margin: 0 15px;
		}
		&.arr {
			.slick-arrow {
				bottom: 5px;
			}
		}
		.reviews__item {
			padding: 20px;
			p {
				margin-bottom: 10px;
			}
		}
	}
	@media screen and (max-width: 600px) {
		.reviews__item {
			.reviews__img {
				width: 80px;
				height: 80px;
			}
			.reviews__right {
				width: calc(100% - 80px);
				padding: 10px 0 0 10px;
			}
		}
	}
}
/* main reviews */

/* main news */
.news {
	margin-bottom: -185px;
	.container {
		padding-bottom: 185px;
	}
	.beige {
		margin-bottom: 45px;
	}
	@media screen and (max-width: 1440px) {
		.container {
			padding-bottom: 150px;
		}
		.beige {
			margin-bottom: 30px;
		}
	}
	@media screen and (max-width: 1200px) {
		margin-bottom: 0;
		.container {
			padding-bottom: 100px;
		}
		.beige {
			margin-bottom: 20px;
		}
	}
	@media screen and (max-width: 1024px) {
		.beige {
			margin-bottom: 15px;
		}
		.container {
			padding-bottom: 30px;
		}
	}
	@media screen and (max-width: 768px) {
		.container {
			padding-bottom: 10px;
		}
	}
}
.news__block {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.news__item {
		display: flex;
		width: calc(50% - 15px);
		flex-direction: column;
		background-color: #fff;
		color: #474747;
		transition: 0ms;
		.news__img {
			height: 250px;
			position: relative;
			&:after {
				content: '';
				background-color: rgba(0,0,0,0.3);
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				transition: 300ms;
				opacity: 0;
			}
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center center;
				transition: 300ms;
			}
			.btn {
				display: block;
				width: 195px!important;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				transform: translateY(-50%);
				margin: 0 auto;
				z-index: 1;
				opacity: 0;
				&.more {
					padding-left: 35px!important;
					padding-right: 50px!important;
					&:after {
						right: 35px;
					}
					&:before {
						right: 42px;
					}
				}
			}
		}
		.news__info {
			flex: 1 0 auto;
			border: 1px solid #ebebeb;
			border-top: none;
			padding: 35px 30px 25px;
			transition: 300ms;
		}
		.date {
			display: block;
			color: #999999;
			margin: 0 0 20px;
		}
		p {
			margin-bottom: 10px;
		}
		h3 {
			margin: 0 0 10px;
			transition: 300ms;
		}
		&:hover {
			color: #efefef;
			h3 {
				color: #c39570;	
			}
			.news__img {
				&:after {
					opacity: 1;
				}
				.btn {
					opacity: 1;
				}
			}
			.news__info {
				background-color: #171717;
				border: 1px solid #171717;
				border-top: none;
			}
		}
	}
	@media screen and (max-width: 1200px) {
		.news__item {
			width: calc(50% - 10px);
		}
	}
	@media screen and (max-width: 1024px) {
		.news__item {
			.news__img {
				height: 220px;
				.btn {
					width: 170px!important;
				}
			}
			.news__info {
				padding: 25px;
			}
			.date {
				margin-bottom: 15px;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.news__item {
			.news__img {
				height: 175px;
			}
			.news__info {
				padding: 20px 20px 15px;
			}
			.date {
				margin-bottom: 10px;
			}
		}
	}
	@media screen and (max-width: 480px) {
		.news__item {
			width: 100%;
			margin: 0 0 15px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}
/* main news */

/* main instagramm */
.inst {
	.container {
		padding: 110px 15px 0;
	}
	h2 {
		margin-bottom: 20px;
		a {
			font-family: $regularV;
			color: #c39570;
		}
	}
	.title--left {
		padding-right: 0;
		padding-left: 40px;
		margin-left: 140px;
		&:before {
			display: block;
		}
		&:after {
			display: none;
		}
	}
	@media screen and (max-width: 1440px) {
		.title--left {
			padding-left: 30px;
			margin-left: 75px;
		}
		.container {
			padding-top: 100px;
		}
	}
	@media screen and (max-width: 1200px) {
		.container {
			padding-top: 75px;
		}
	}
	@media screen and (max-width: 1024px) {
		.container {
			padding-top: 50px;
		}
		.title--left {
			padding-left: 20px;
			margin-left: 0;
		}
	}
	@media screen and (max-width: 768px) {
		.container {
			padding-top: 30px;
		}
		h2 {
			margin-bottom: 15px;
		}
	}
}
.inst__slider {
	background-color: #171717;
	padding: 30px 15px;
	.slick-slide {
		height: 285px;
		position: relative;
		margin: 0 15px;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center center;
		}
		&:before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.5);
			transition: 300ms;
			z-index: 1;
			opacity: 0;
		}
		&:after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 2px solid rgba(195, 149, 112, 0.8);
			transition: 300ms;
			opacity: 0;
			z-index: 1;
			border-radius: 5px;
		}
		i {
			font-size: 36px;
			color: #fff;
			position: absolute;
			top: 50%;
			left: 15px;
			right: 15px;
			text-align: center;
			transform: translateY(-50%);
			transition: 300ms;
			z-index: 1;
			opacity: 0;
		}
		&:hover {
			&:after {
				top: 10px;
				left: 10px;
				right: 10px;
				bottom: 10px;
				opacity: 1;
			}
			&:before {
				opacity: 1;
			}
			i {
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 1440px) {
		.slick-slide {
			margin: 0 10px;
		}
	}
	@media screen and (max-width: 1200px) {
		margin: 0;
		.slick-slide {
			margin: 0 10px;
			&:after {
				opacity: 1;
				top: 10px;
				left: 10px;
				right: 10px;
				bottom: 10px;
			}
			&:before {
				opacity: 1;
			}
			i {
				font-size: 26px;
				opacity: 1;
			}
		}
	}
	@media screen and (max-width: 768px) {
		.slick-slide {
			height: 250px;
		}
	}
}
/* main instagramm */

.map {
	height: 450px;
	@media screen and (max-width: 1440px) {
		height: 400px;
	}
	@media screen and (max-width: 1280px) {
		height: 350px;
	}
	@media screen and (max-width: 1024px) {
		height: 300px;
	}
	@media screen and (max-width: 768px) {
		height: 250px;
	}
}

/* main content */