/* page content */
.page {
	.news1, .news {
		.date {
			color: #c39570;
		}
	}
	.page_nav {
		.active {
			color: #c39570;
		}
	}
    #ask-form {
        max-width: 350px;
    }
    .write {
        color: #c39570;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    .back_link {
        margin: 25px 0;
    }
    p a {
        color: #c39570;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .h2 a {
        font-size: 16px;
        color: #c39570;
    }
    .faq--form {
        background: url(../img/img-41.jpg) repeat;
    }
    .faq--form .faq--form__txt,
    .rev .rev__item .rev__text {
        background: url(../img/img-41.jpg) repeat;
    }
    .rev .rev__item .rev__text:after {
        border-right: 12px solid #e9cfac;
    }
    .rev .rev__item .rev__user .rev__user--name:after {
        color: #c39570;
    }
	@media screen and (max-width: 1024px) {
		#photos-list td img {
			width: 100%;
			height: auto;
		}
	}
	@media screen and (max-width: 480px) {
		p img, p strong img, h2 img, h3 img, h4 img, h5 img, h6 img, p a img, strong img {
			width: 100%!important;
			height: auto!important;
			display: block!important;
			float: none!important;
			margin: 0 0 10px 0!important;		
		}
        #page-text p a img {
            width: auto!important;
            max-width: 100%!important;
        }
		#photos-list td {
			width: 50%;
			@include inline-block;
		}
	}
}
/* breadcrumbs */
.breadcrumbs {
    font-size: 14px;
    margin-bottom: 60px;
    .container {
        display: flex;
        min-height: 195px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &.bg {
        > img {
            object-position: center top;
        }
    }
	ul {
		@include ul-default;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
        margin-bottom: 20px;
		li {
			padding: 5px 0px;
			color: #cccccc;
			@include inline-block;
			a {
                color: #c39570;
				text-decoration: none;
                &:hover {
                    color: #cccccc;
                }
            }
			&:after {
                content: '\f105';
                font-family: 'fontAwesome';
				margin: 2px 7px 0px 7px;
				color: #c39570;
				@include inline-block;
			}
			&:last-of-type:after {
				display: none;
			}
		}
    }
    h1 {
        margin: 0;
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 50px;
    }
    @media screen and (max-width: 1200px) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 1024px) {
        .container {
            min-height: 100px;
            padding: 15px;
        }
        ul {
            margin-bottom: 10px;
            li {
                padding: 2px 0;
                &:after {
                    margin: 2px 5px 0px 5px;
                }
            }
        }
    }
}
/* breadcrumbs */

/* page about */
.page {
    .about {
        margin-top: -60px;
    }
    .icon {
        margin-top: 30px;
        max-width: 100%;
    }
    .beige {
		margin-top: -30px;
	}
    .about__block {
        margin-bottom: 20px;
    }
    @media screen and (max-width: 1440px) {
        .about {
            margin-top: -50px;
        }
    }
    @media screen and (max-width: 1200px) {
        .about {
            margin-top: -30px;
        }
    }
    @media screen and (max-width: 1024px) {
        .beige {
            margin-top: -20px;
            margin-bottom: 15px;
        }
        .icon {
            margin-top: 10px;
        }
    }
}
/* page about */

/* page catalog */
.page {
    .item__block {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -15px;
        .item {
            margin-bottom: 30px;
            &:hover {
                z-index: 2;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .item__block {
            margin: 0 -10px;
            .item {
                margin-bottom: 20px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .item__block {
            margin: 0 -7.5px;
        }
    }
    @media screen and (max-width: 600px) {
        .item__block {
            margin: 0;
        }
    }
}
.cat--unit {
    display: flex;
    flex-wrap: wrap;
    background: url(../img/img-41.jpg) repeat;
    padding-bottom: 20px;
    .left {
        display: flex;
        width: 50%;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        
    }
    .right {
        width: 50%;
        position: relative;
        border-radius: 0px 5px 5px 0;
    }
    .cat--unit__block {
        padding: 55px 30px 25px;
        border-bottom: 1px solid #ffffff;
        &:last-of-type {
            border-bottom: none;
        }
    }
    .cat--unit__price {
        display: flex;
        margin-bottom: 20px;
    }
    h4 {
        margin: 0 0 15px;
    }
    .qt {
        height: 50px;
    }
    .price {
        display: block;
        margin-bottom: 28px;
        span {
            font-family: $medium;
            font-size: 24px;
            color: #333333;
        }
    }
    .btn {
        margin: 0 0 0 28px;
    }
    .att {
        width: 100%;
        color: #565049;
        padding: 0 15px;
        margin: 15px 0 0 0;
        span {
            color: red;
        }
    }
    @media screen and (max-width: 1024px) {
        .cat--unit__block {
            padding: 30px 20px 15px;
        }
        .price {
            span {
                font-size: 20px;
            }
        }
        .qt {
            height: 45px;
        }
        .att {
            bottom: 30px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-wrap: wrap;
        .left {
            width: 100%;
            margin-bottom: 15px;
        }
        .right {
            width: 100%;
            padding: 0 0 10px;
            border-radius: 5px;
        }
        .att {
            position: relative;
            left: 0;
            bottom: 0;
        }
        .price {
            margin-bottom: 20px;
        }
        .cat--unit__block {
            padding: 20px 20px 15px;
        }
        .qt {
            height: 40px;
        }
        .btn {
            margin-left: 15px;
        }
    }
}
.slider__nav {
    width: 100%;
    order:2;
    padding-right: 80px;
    margin-top: 15px;
    .slick-slide {
        height: 83px;
        outline: none;
        position: relative;
        margin-right: 10px;
        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border: 3px solid #c39570;
            transition: 300ms;
            opacity: 0;
        }
        img {
            widtH: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        &:hover, &.slick-current {
            &:after {
                opacity: 1;
            }
        }
    }
    .slick-arrow {
        border-radius: 5px;
        overflow: hidden;
        transition: 300ms;
        &.slick-prev {
            display: none;
        }
        &.slick-next {
            width: 83px;
            height: 66px;
            background: url(../img/img-3.jpg) repeat;
            z-index: 1;
            left: auto;
            right: 5px;
            top: 50%;
            transform: translateY(-50%) rotate(-90deg);
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: url('../img/down.png') no-repeat center center;
            }
        }
        &:before {
            display: none;
        }
        &:hover {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-arrow {
            &.slick-next {
                right: 0;
            }
        }
    }
    @media screen and (max-width: 600px) {
        padding-right: 60px;
        .slick-slide {
            height: 65px;
        }
        .slick-arrow {
            &.slick-next {
                width: 65px;
                height: 50px;
                &:after {
                    background-size: 25px;
                }
            }
        }
    }
   
}
.slider__for {
    width: 100%;
    order: 1;
    .slick-slide {
        // height: 460px;
        &.radius {
            border-radius: 5px 0px 0px 5px;
        }
        img {
            width: 100%;
            // height: 100%;
            // object-fit: cover;
            // object-position: center center;
        }
    }
    @media screen and (max-width: 1024px) {
        .slick-slide {
            height: 380px;
        }
    }
    @media screen and (max-width: 768px) {
        .slick-slide {
            height: 360px;
            &.radius {
                border-radius: 5px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        width: 100%;
    }
    @media screen and (max-width: 480px) {
        .slick-slide {
            height: 290px;
        }
    }
}
.similar {
    margin-top: 115px;
    .items__slider  {
        &.dots {
            .slick-dots {
                li {
                    button {
                        &:hover {
                            border: 1px solid #171717;
                            &:after {
                                background-color: #171717;
                            }
                        }
                    }
                    &.slick-active {
                        button {
                            border: 1px solid #171717;
                            &:after {
                                background-color: #171717;
                            }
                        }
                    }
                }
            }
        }
        &.arr {
            .slick-arrow {
                &:after {
                    background: url(../img/prev1.png) no-repeat center center;
                }
            }
        }
    }
    @media screen and (max-width: 1440px) {
        margin-top: 100px;
        .items__slider {
            &.arr {
                .slick-arrow {
                    &:after {
                        background-size: contain;
				        background-position: center center;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1200px) {
        margin-top: 75px;
    }
    @media screen and (max-width: 1024px) {
        margin-top: 50px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 30px;
    }
}
/* page catalog */

/* page basket */
.basket {
    width: 100%;
    overflow: hidden;
    border-radius: 5px;
    border-collapse: collapse;
    tr {
        border-bottom: 1px solid #fff;
        &:last-child {
            border-bottom: none;
        }
        th {
            min-width: 135px;
            font-weight: normal;
            background: url(../img/img-48.jpg) repeat;
            padding: 15px;
            text-align: left;
        }
        td {
            background: url(../img/img-51.jpg) repeat;
            padding: 15px;
        }
    }
    .b--head {
        .b--name {
            width: 580px;
            padding-left: 160px;
        }
        .b--price-t {
            background: url(../img/img-49.jpg) repeat;
            text-align: center;
        }
        .b--qt {
            text-align: center;
        }
        .b--close {
            min-width: 100px;
        }
    }
    .b--body {
        background: url(../img/img-41.jpg) repeat;
        .b--price-t {
            background: url(../img/img-50.jpg) repeat;
            text-align: center;
        }
    }
    .basket__item {
        display: flex;
        align-items: center;
        .basket__img {
            width: 100px;
            img {
                display: block;
                width: 100%;
            }
        }
        .basket__info {
            width: calc(100% - 100px);
            padding-left: 25px;
            .basket__title {
                font-family: $regularV;
                font-size: 36px;
                color: #171717;
            }
        }
    }
    .qt {
        margin: 0 auto;
    }
    .close {
        position: relative;
        top: 0;
        right: 0;
        margin: 0 auto;
    }
    @media screen and (max-width: 1024px) {
        .basket__item .basket__info {
            padding-left: 15px;
            .basket__title {
                font-size: 28px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        tbody {
            display: block;
        }
        tr {
            display: flex;
        }
        .b--head {
            display: none;
        }
        .b--body {
            display: flex;
            position: relative;
        }
        .b--name {
            width: 100%;
            padding-right: 40px;
            padding-bottom: 70px;
        }
        .b--price {
            display: none;
        }
        .b--qt {
            position: absolute;
            bottom: 15px;
            left: 15px;
            padding: 0;
        }
        .b--price-t {
            background: transparent!important;
            padding: 0;
            position: absolute;
            bottom: 22px;
            left: 150px;
        }
        .b--close {
            padding: 0;
            position: absolute;
            right: 10px;
            top: 12px;
        }
        .basket__item {
            .basket__info {
                .basket__title {
                    font-size: 22px;
                }
            }
        }
    }
}
.formalization {
    background: url(../img/img-41.jpg) repeat;
    padding: 85px 0;
    .form__row {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 15px;
        &.down {
            .check {
                &:last-of-type {
                    margin-right: 50px;
                }
            }
        }
    }
    .form__item {
        widtH: calc(50% - 15px);
        &.right {
            .check {
                margin: 18px 0 28px 0;
                &:last-of-type {
                    margin-left: 100px;
                }
            }
        }
    }
    h5 {
        width: 100%;
        margin: 0 0 25px;
    }
    .form__inp {
        display: flex;
        justify-content: space-between;
        input[type="text"] {
            width: calc(33.33% - 10px);
        }
    }
    .check {
        &:first-of-type {
            margin-right: 105px;
        }
        &.sec {
            margin-right: 50px;
        }
    }
    .price--center {
        display: block;
        text-align: center;
    }
    .btn {
        margin: 30px auto 0;
    }
    @media screen and (max-width: 1024px) {
        .check {
            &:first-of-type {
                margin-right: 50px;
            }
        }
        .form__item {
            width: calc(50% - 7.5px);
            &.right {
                .check {
                    margin-bottom: 22px;
                    &:last-of-type {
                        margin-left: 30px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        padding: 50px 0;
        .form__item {
            width: 100%;
            &.right {
                .check:last-of-type {
                    margin-bottom: 15px;
                }
            }
        }
        h5 {
            margin: 0 0 15px;
        }
        .check:first-of-type {
            margin-right: 0;
        }
    }
    @media screen and (max-width: 600px) {
        padding: 30px 0;
        .check {
            display: table;
            margin: 0 0 10px!important;
        }
        .btn {
            margin-top: 20px;
        }
    }
}
.del {
    display: none;
}
.check {
    @include inline-block;
    cursor: pointer;
    user-select: none;
    margin-bottom: 20px;
    input {
        display: none;
        &:checked + span {
            &:after {
                opacity: 1;
            }
        }
    }
    span {
        display: table;
        position: relative;
        padding-left: 22px;
        &:before {
            content: '';
            width: 12px;
            height: 12px;
            background-color: #fff;
            border-radius: 100%;
            position: absolute;
            top: 3px;
            left: 0px;
            border: 1px solid #d2d2d1;
        }
        &:after {
            content: '';
            position: absolute;
            width: 6px;
            height: 6px;
            background-color: #c79c7a;
            top: 7px;
            left: 4px;
            border-radius: 100%;
            transition: 300ms;
            opacity: 0;
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 15px;
    }
}
.basket--price {
    display: block;
    text-align: right;
    margin: 18px 140px 50px;
    @media screen and (max-width: 1024px) {
        margin: 15px 0 30px;
    }
}
/* page basket */

/* page contacs */
.socials {
    display: flex;
    li {
        margin-right: 25px;
        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            font-size: 22px;
            background-color: #3a3a3a;
            color: #9d9d9d;
            border-radius: 100%;
            &:hover {
                background-color: #907056;
                color: #fff;
            }
        }
    }
    @media screen and (max-width: 768px) {
        li {
            a {
                font-size: 20px;
            }
        }
    }
    @media screen and (max-width: 600px) {
        li {
            a {
                width: 40px;
                height: 40px;
            }
        }
    }
}
.cts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 100px;
    .cts__item {
        width: calc(50% - 30px);
        h3 {
            margin: 0 0 20px;
        }
    }
    input[type="text"],
    input[type="email"] {
        min-height: 42px;
        padding: 10px 0;
        border: none;
        outline: none;
        border-radius: 0px;
        border-bottom: 1px solid #d5b59b;
        margin: 20px 0 0 0;
    }
    textarea {
        height: 120px;
        padding: 20px 20px;
        margin-top: 40px;
        resize: none;
        border-radius: 0px;
    }
    ul {
        @include ul-default;
    }
    .cts__list {
        margin-top: 38px;
        li {
            margin: 0 0 20px;
        }
    }
    .col--location {
        &:after {
            font-size: 15px;
            top: 2px;
        }
    }
    .col--phone {
        &:after {
            top: 3px;
        }
    }
    .col--time {
        &:after {
            top: 3px;
        }
    }
    .socials {
        margin: 30px 0;
        li {
            a {
                background-color: #c39570;
                color: #e1cab8;
                &:hover {
                    background-color: #171717;
                    color: #bababa;
                }
            }
        }
    }
    .btn {
        margin-top: 15px;
    }
    @media screen and (max-width: 1440px) {
        margin-bottom: 75px;
    }
    @media screen and (max-width: 1024px) {
        margin-bottom: 50px;
        .cts__item {
            width: calc(50% - 7.5px);
        }
        .socials {
            li {
                margin-right: 15px;
            }
        }
        input[type="text"],
        input[type="email"] {
            min-height: 40px;
            padding: 8px 0;
            margin-top: 15px;
        }
        textarea {
            height: 100px;
            padding: 10px 15px;
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 768px) {
        margin-bottom: 30px;
    }
    @media screen and (max-width: 600px) {
        flex-direction: column;
        .cts__item {
            width: 100%;
            h3 {
                margin-bottom: 15px;
            }
        }
        .cts__list {
            margin-top: 15px;
            li {
                margin-bottom:  15px;
            }
        }
        .socials {
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 340px) {
        .g-recaptcha {
            width: 280px;
            margin: 0 auto;
            > div {
                transform: scale(0.9);
                transform-origin: 0 0;
            }
        }
        .btn {
            margin-top: 10px;
        }
    }
}
.cts__form {
    input[type="text"] {
        &:first-of-type {
            margin-top: 0;
        }
    }
}
/* page contacs */

/* table menu */
.welcome__img {
	display: block;
	width: 100%;
	height: 100%;
	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center center;
	}
}

.table--menu {
    font-family: $regularL;
    background: url(../img/img-41.jpg) repeat;
    padding-top: 68px;
}

.table--header {
    height: 68px;
    background: url(../img/img-50.jpg) repeat;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
    z-index: 9;
    .logo {
        display: flex;
        background-color: #202020;
        width: 255px;
        align-items: center;
        justify-content: center;
    }
    .right {
        width: calc(100% - 255px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
    }
    h1 {
        font-family: $regularV;
        font-size: 34px;
        margin: 0;
        text-transform: inherit;
    }
    .search {
        width: 230px;
        height: 26px;
        position: relative;
        padding-right: 20px;
        border-bottom: 1px solid #202020;
        input[type="search"] {
            background: transparent;
            width: 100%;
            font-family: $regular;
            font-size: 14px;
            border: none;
            padding: 5px 10px 5px 0;
            outline: none;
            &::-webkit-search-cancel-button {
                appearance: none;
            }
        }
        button {
            font-family: 'fontAwesome';
            font-size: 16px;
            background: transparent;
            padding: 0;
            border: none;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            &:after {
                content: '\f002';
            }
        }
    }
}
.table--content {
    display: flex;
    height: 100%;
    padding-left: 255px;
    h2 {
        font-family: $regularV;
        font-size: 34px;
        margin-bottom: 15px;
    }
    > .left {
        position: fixed;
        width: 255px;
        padding-top: 1px;
        left: 0;
        top: 68px;
        bottom: 0;
        background-color: #131313;
        z-index: 1;
    }
    > .right {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px;
        line-height: 1;
    }
    .table--list {
        @include ul-default;
        height: calc(100% - 90px);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        > li {
            border-bottom: 2px solid #202020;;
            > a {
                display: flex;
                height: 66px;
                font-family: $boldSBL;
                color: #cecece;
                align-items: center;
                position: relative;
                padding: 10px 40px 10px 65px;
                &:after {
                    content: '\f105';
                    font-family: 'fontAwesome';
                    font-size: 16px;
                    color: #616161;
                    position: absolute;
                    top: 50%;
                    right: 20px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                img {
                    width: 33px;
                    height: 29px;
                    object-fit: contain;
                    object-position: center center;
                    position: absolute;
                    top: 50%;
                    left: 20px;
                    transform: translateY(-50%);
                }
            }
            &.active > a {
                background-color: #333333;
            }
            ul {
                width: 182px;
                background-color: #333333;
                position: fixed;
                top: 69px;
                left: 72px;
                bottom: 90px;
                padding: 20px;
                overflow: auto;
                li {
                    margin: 0 0 20px;
                    a {
                        font-family: $boldSBL;
                        font-size: 16px;
                        color: #cecece;
                        &:hover {
                            color: #cecece;
                        }
                    }
                    &.active a {
                        color: #c39570;
                    }
                }
            }
        }
    }
    .table--down {
        display: flex;
        align-items: center;
        height: 89px;
        background-color: #333333;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        font-family: $italic;
        font-size: 16px;
        color: #cccccc;
        padding: 20px;
    }
    .slider {
        margin-bottom: 35px!important;
        .slick-arrow {
            display: none!important;
        }
        .slick-slide {
            &:after {
                background: linear-gradient(to right, rgba(0,0,0,0.5), rgba(0,0,0,0));
            }
            .slider__block {
                width: 340px;
                font-size: 16px;
                color: #d7d2d0;
                text-align: left;
                position: absolute;
                left: 35px;
                right: 35px;
                margin: 0;
                .slider__title {
                    display: block;
                    font-family: $regularV;
                    font-size: 42px;
                    color: #ffffff;
                    border-bottom: 1px solid rgba(255,255,255,0.7);
                    padding: 0 0 20px;
                    margin-bottom: 25px;
                    line-height: 1.2;
                }
                p {
                    line-height: 1.2;
                    span {
                        font-family: $heavyL;
                        font-size: 22px;
                    }
                }
            }
        }
        .slick-dots {
            bottom: 20px;
            li {
                width: auto;
                height: auto;
                margin: 0 5px;
                button {
                    width: 10px;
                    height: 10px;
                    padding: 0px;
                    background-color: #e7dfd8;
                    border-radius: 100%;
                    transition: 300ms;
                    &:before {
                        display: none;
                    }
                }
                &.slick-active {
                    button {
                        background-color: #9c6f4b;
                    }
                }
            }
        }
    }
}
.tabke--about {
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    padding: 0 35px;
    .left {
        width: calc(100% - 235px);
    }
    img {
        max-width: 235px;
        margin-top: 15px;
    }
    p {
        line-height: 1.4;
    }
    .btn {
        height: 50px;
        font-family: $boldSBL;
        background-color: #9c6f4b!important;
        font-size: 12px;
        color: #fff!important;
        text-transform: uppercase;
        padding: 15px 30px!important;
        border-radius: 4px!important;
    }
}
.table--inside {
    .table--list {
        > li {
            > a {
                width: 72px;
                font-size: 12px;
                justify-content: center;
                align-items: flex-start;
                text-align: center;
                padding: 40px 5px 10px;
                &:after {
                    display: none;
                }
                img {
                    width: 28px;
                    height: 25px;
                    top: 19px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                }
            }
        }
    }
}
.table--title {
    display: flex;
    min-height: 82px;
    font-size: 14px;
    color: #333333;
    background-color: rgba(195, 149, 112, 0.3);
    padding: 0px 0 0px 85px;
    position: relative;
    margin-bottom: 10px;
    h3 {
        font-family: $regularV;
        font-size: 24px;
        margin: 0 0 8px;
    }
    .icon {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        img {
            width: 100%;
        }
    }
    p {
        margin: 0;
    }
    .table--txt {
        width: calc(100% - 119px);
        padding: 15px 0 10px;
    }
    .time {
        width: 119px;
        background-color: rgba(156, 111, 75, 0.3);
        padding: 50px 10px 15px;
        border-left: 1px solid #e2ceb4;;
        position: relative;
        text-align: center;
        &:after {
            content: '\f017';
            font-family: 'fontAwesome';
            font-size: 30px;
            position: absolute;
            left: 10px;
            right: 10px;
            top: 10px;
            color: #423e3b;
            text-align: center;
        }
        h5 {
            font-family: $regularV;
            font-size: 18px;
            color: #1e1e1e;
            margin: 0;
        }
    }
}
.table--blocks {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
    .table--item {
        width: calc(33.33% - 20px);
        background-color: #ffffff;
        color: #5c5c5c;
        text-align: center;
        position: relative;
        margin: 0 10px 20px;
        .table--img {
            height: 157px;
            position: relative;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
            }
        }
        .icon {
            width: 30px;
            position: absolute;
            right: 18px;
            top: 15px;
            img {
                width: 100%;
            }
        }
        .table--info {
            padding: 18px 20px 60px;
        }
        .stiker {
            position: absolute;
            top: 0;
            left: 20px;
            top: 15px;
            line-height: 1;
            span {
                display: block;
                font-family: $boldSBL;
                font-size: 11px;
                color: #ffffff;
                text-transform: uppercase;
                text-align: center;
                padding: 5px 10px;
                margin-bottom: 5px;
                border-radius: 3px;
                &.green {
                    background-color: #a8c256;
                }
                &.red {
                    background-color: #990000;
                }
            }
        }
        h3 {
            font-size: 23px;
            color: #171717;
            text-align: center;
            margin: 0 0 10px;
        }
        .price {
            font-weight: bold;
            span {
                font-size: 18px;
                color: #666666;
            }
        }
        .down {
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 20px;
            padding-top: 15px;
            border-top: 1px solid #d2d2d2;
            &.center {
                justify-content: center;
            }
        }
    }
}
.table--modal {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
    &:after {
        content: '';
        background-color: #000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -2;
        opacity: 0.7;
    }
    .table--modal__block {
        height: 100%;
        background-color: #fff;
        font-size: 16px;
        margin-left: 343px;
    }
    .slider {
        margin: 0!important;
        .slick-slide {
            height: 50vh;
        }
    }
    .table--modal__img {
        height: 50%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
    .table--modal__info {
        padding: 50px 40px;
    }
    .table--info__down {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 40px;
        border-top: 1px solid #d2d2d2;
        margin-top: 30px;
    }
    .table--time {
        position: relative;
        font-family: $boldSBL;
        color: #c39570;
        padding-left: 30px;
        &:after {
            content: '';
            width: 22px;
            height: 22px;
            background: url(../img/img-72.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        &.table--user {
            &:after {
                background: url(../img/img-73.png) no-repeat center center;
                background-size: contain;
            }
        }
    }
    h3 {
        font-family: $regularV;
        font-size: 30px;
        color: #171717;
        margin: 0 0 15px;
    }
    .price {
        span {
            font-size: 22px;
            color: #666666;
        }
    } 
    .close {
        width: 32px;
        height: 32px;
        right: calc(100% - 305px);
        top: 20px;
        &:before, &:after {
            background-color: #fff;
            width: calc(100% + 5px);
            height: 2px;
            left: 50%;
            margin-left: -60%;
        }
    }
}
/* table menu */

/* pagination */
.pagination {
    line-height: 1;
    margin: 10px 0 0;
    ul {
        @include ul-default;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        li {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 10px;
            text-align: center;
            a {
                display: block;
                font-family: $regularV;
                font-size: 30px;
                color: #c39570;
                text-align: center;
                &:hover {
                    color: #171717;
                    text-decoration: underline;
                    text-decoration-color: #8b8b8b;
                }
            }
            &.active a {
                color: #171717;
                text-decoration: underline;
                text-decoration-color: #8b8b8b;
            }
            &.prev, &.next {
                a {
                    font-size: 24px;
                    &:after {
                        content: '\f101';
                        font-family: 'fontAwesome';
                        line-height: 18px;
                        margin-top: -5px;
                    }
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            &.next {
                margin-left: 15px;
            }
            &.prev {
                margin-right: 15px;
                a {
                    &:after {
                        content: '\f100';
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        ul {
            li {
                a {
                    font-size: 24px;
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        ul {
            li {
                margin: 0 8px;
                a {
                    font-size: 20px;
                }
                &.prev, &.next {
                    a {
                        &:after {
                            font-size: 18px;
                            margin-top: -2px;
                        }
                    }
                }
            }
        }
    }
}
/* pagination */


/* modal */
.modal {
    display: none;
    background-color: rgba(0,0,0,0.7);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    .modal__layer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .modal__block {
        width: 420px;
        background-color: #fff;
        position: absolute;
        padding: 20px;
        left: 0;
        right: 0;
        top: 50%;
        margin: 0 auto;
        transform: translateY(-50%);
        h4 {
            font-size: 20px;
            text-align: center;
            padding: 0 10px;
            margin: 0;
        }
    }
    form {
        display: block;
        padding-bottom: 130px;
        margin-top: 15px;
        textarea, 
        input[type="text"], 
        input[type="email"], 
        input[type="search"], 
        input[type="password"] {
            min-height: 52px;
            width: 100%;
            background-color: #fff;
            font-family: $regular;
            font-size: 16px;
            padding: 15px 25px;
            margin-bottom: 15px;
            box-shadow: none;
            border: 1px solid #d0ab8a;
            border-radius: 4px;
            &::placeholder {
                color: #a3a3a3;
            }
        }
        .g-recaptcha {
            float: right;
        }
        .btn {
            float: right;
            margin: 15px 0 0 0;
        }
    }
    @media screen and (max-width: 1200px) {
        .modal__block {
            h4 {
                font-size: 18px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        form {
            textarea, 
            input[type="text"], 
            input[type="email"], 
            input[type="search"], 
            input[type="password"] {
                min-height: 45px;
                padding: 11px 15px;
            }
        }
    }
    @media screen and (max-width: 768px) {
        .modal__block {
            h4 {
                font-size: 16px;
            }
        }
    }
    @media screen and (max-width: 500px) {
        .modal__block {
            width: auto;
            left: 10px;
            right: 10px;
        }
    }
    @media screen and (max-width: 380px) {
        form {
            .g-recaptcha {
                transform: scale(0.85);
                transform-origin: 100% 50%;
            }
        }
    }
}

.call--btn {
    position: fixed;
    bottom: 100px;
    right: 40px;
    display: table;
    background-color: #ffcc00;
    font-family: 'FontAwesome';
    border-radius: 100%;
    text-decoration: none!important;
    width: 80px;
    height: 80px;
    text-align: center;
    padding: 23px 5px 5px 5px;
    box-shadow: 0px 0px 15px rgba(0,0,0,0.7);
    box-sizing: border-box;
    outline: none!important;
    z-index: 1;
    &:hover {
        animation-name: phone;
        animation-iteration-count: 1;
        animation-duration: 0.7s;
    }
    i {
        display: block;
        font-size: 35px;
        color: #fff;
    }
    &:after, &:before  {
        content: '';
        width: 90%;
        height: 90%;
        background-color: transparent;
        border: 1px solid #ffcc00;
        position: absolute;
        top: 5%;
        left: 5%;
        border-radius: 100%;
        animation-iteration-count: infinite;
        animation-duration: 1.2s;
        animation-name: ring;
        outline: none;  
    }
    &:after {
        animation-delay: 0.6s;
    }
    &:before {
        animation-delay: 0.8s;
        border: 1px solid #d9af04;
    }
    @media screen and (max-width: 1440px) {
        margin-right: 0;
        right: 35px;
        bottom: 75px;
    }
    @media screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
        padding-top: 15px;
        right: 15px;
        bottom: 80px;
        i {
            font-size: 24px;
        }
    }
    @media screen and (max-width: 768px) {
        bottom: 50px;
    }
}

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.1;
    transform: scale(1.7);
  }
}
@keyframes phone {
  20% {
   transform: rotate(-30deg); 
  }

  80% {
    transform: rotate(390deg)
  }

  100% {
    transform: rotate(360deg);
  }
}

/* modal */

/* page content */