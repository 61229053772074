@charset "UTF-8";
/* Connection Font Icons */
@font-face {
  font-family: 'FontAwesome';
  src: url("../fonts/fontawesome-webfont.eot");
  src: url("../fonts/fontawesome-webfont.eot") format("embedded-opentype"), url("../fonts/fontawesome-webfont.woff2") format("woff2"), url("../fonts/fontawesome-webfont.woff") format("woff"), url("../fonts/fontawesome-webfont.ttf") format("truetype"), url("../fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

/* Connection Font Icons */
/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 2, 2020 */
@font-face {
  font-family: 'bernier_distressedregular';
  src: url("../fonts/BERNIERDistressed-Regular.eot");
  src: url("../fonts/BERNIERDistressed-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BERNIERDistressed-Regular.woff2") format("woff2"), url("../fonts/BERNIERDistressed-Regular.woff") format("woff"), url("../fonts/BERNIERDistressed-Regular.ttf") format("truetype"), url("../fonts/BERNIERDistressed-Regular.svg#bernier_distressedregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'bernier_regularregular';
  src: url("../fonts/BERNIERRegular-Regular.eot");
  src: url("../fonts/BERNIERRegular-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BERNIERRegular-Regular.woff2") format("woff2"), url("../fonts/BERNIERRegular-Regular.woff") format("woff"), url("../fonts/BERNIERRegular-Regular.ttf") format("truetype"), url("../fonts/BERNIERRegular-Regular.svg#bernier_regularregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'bernier_shaderegular';
  src: url("../fonts/BERNIERShade-Regular.eot");
  src: url("../fonts/BERNIERShade-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/BERNIERShade-Regular.woff2") format("woff2"), url("../fonts/BERNIERShade-Regular.woff") format("woff"), url("../fonts/BERNIERShade-Regular.ttf") format("truetype"), url("../fonts/BERNIERShade-Regular.svg#bernier_shaderegular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'violetregular';
  src: url("../fonts/violet-webfont.eot");
  src: url("../fonts/violet-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/violet-webfont.woff2") format("woff2"), url("../fonts/violet-webfont.woff") format("woff"), url("../fonts/violet-webfont.ttf") format("truetype"), url("../fonts/violet-webfont.svg#violetregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_DINPro-Light';
  src: url("../fonts/DINPro-Light.eot");
  src: local("☺"), url("../fonts/DINPro-Light.woff") format("woff"), url("../fonts/DINPro-Light.ttf") format("truetype"), url("../fonts/DINPro-Light.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_DINPro-Black';
  src: url("../fonts/DINPro-Black.eot");
  src: local("☺"), url("../fonts/DINPro-Black.woff") format("woff"), url("../fonts/DINPro-Black.ttf") format("truetype"), url("../fonts/DINPro-Black.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_DINPro-Italic';
  src: url("../fonts/DINPro-Italic.eot");
  src: local("☺"), url("../fonts/DINPro-Italic.woff") format("woff"), url("../fonts/DINPro-Italic.ttf") format("truetype"), url("../fonts/DINPro-Italic.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_DINPro-Medium';
  src: url("../fonts/DINPro-Medium.eot");
  src: local("☺"), url("../fonts/DINPro-Medium.woff") format("woff"), url("../fonts/DINPro-Medium.ttf") format("truetype"), url("../fonts/DINPro-Medium.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_DINPro-Bold';
  src: url("../fonts/DINPro-Bold.eot");
  src: local("☺"), url("../fonts/DINPro-Bold.woff") format("woff"), url("../fonts/DINPro-Bold.ttf") format("truetype"), url("../fonts/DINPro-Bold.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Conv_DINPro';
  src: url("../fonts/DINPro.eot");
  src: local("☺"), url("../fonts/DINPro.woff") format("woff"), url("../fonts/DINPro.ttf") format("truetype"), url("../fonts/DINPro.svg") format("svg");
  font-weight: normal;
  font-style: normal; }

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on March 17, 2020 */
@font-face {
  font-family: 'latobold';
  src: url("../fonts/lato-bold-webfont.eot");
  src: url("../fonts/lato-bold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-bold-webfont.woff2") format("woff2"), url("../fonts/lato-bold-webfont.woff") format("woff"), url("../fonts/lato-bold-webfont.ttf") format("truetype"), url("../fonts/lato-bold-webfont.svg#latobold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'latoitalic';
  src: url("../fonts/lato-italic-webfont.eot");
  src: url("../fonts/lato-italic-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-italic-webfont.woff2") format("woff2"), url("../fonts/lato-italic-webfont.woff") format("woff"), url("../fonts/lato-italic-webfont.ttf") format("truetype"), url("../fonts/lato-italic-webfont.svg#latoitalic") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'latomedium';
  src: url("../fonts/lato-medium-webfont.eot");
  src: url("../fonts/lato-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-medium-webfont.woff2") format("woff2"), url("../fonts/lato-medium-webfont.woff") format("woff"), url("../fonts/lato-medium-webfont.ttf") format("truetype"), url("../fonts/lato-medium-webfont.svg#latomedium") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'latoregular';
  src: url("../fonts/lato-regular-webfont.eot");
  src: url("../fonts/lato-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-regular-webfont.woff2") format("woff2"), url("../fonts/lato-regular-webfont.woff") format("woff"), url("../fonts/lato-regular-webfont.ttf") format("truetype"), url("../fonts/lato-regular-webfont.svg#latoregular") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'latosemibold';
  src: url("../fonts/lato-semibold-webfont.eot");
  src: url("../fonts/lato-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-semibold-webfont.woff2") format("woff2"), url("../fonts/lato-semibold-webfont.woff") format("woff"), url("../fonts/lato-semibold-webfont.ttf") format("truetype"), url("../fonts/lato-semibold-webfont.svg#latosemibold") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'latoheavy';
  src: url("../fonts/lato-heavy-webfont.eot");
  src: url("../fonts/lato-heavy-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/lato-heavy-webfont.woff2") format("woff2"), url("../fonts/lato-heavy-webfont.woff") format("woff"), url("../fonts/lato-heavy-webfont.ttf") format("truetype"), url("../fonts/lato-heavy-webfont.svg#latoheavy") format("svg");
  font-weight: normal;
  font-style: normal; }

* {
  box-sizing: border-box; }

*:after, *:before {
  display: block;
  line-height: 1; }

[data-src] {
  opacity: 0; }
  [data-src][src] {
    opacity: 1;
    transition-duration: 400ms;
    transition-delay: 0.2s; }

.preload {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  pointer-events: none; }
  .preload.load {
    opacity: 0;
    transition: 500ms; }
  .preload.loadR {
    opacity: 1;
    transition: 500ms; }

@keyframes sk-double-bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

body {
  display: flex;
  height: 100vh;
  min-width: 300px;
  position: relative;
  flex-direction: column;
  font-family: "Conv_DINPro";
  color: #333333;
  -webkit-text-size-adjust: 100%;
  padding-top: 130px;
  margin: 0; }
  body.welcome {
    min-width: 1000px;
    padding: 0; }
  @media screen and (max-width: 1024px) {
    body {
      padding-top: 100px; } }

h1 {
  font-family: "bernier_distressedregular";
  font-size: 48px;
  text-transform: uppercase;
  font-weight: normal;
  text-align: center;
  color: #171717;
  line-height: 1;
  letter-spacing: 2px;
  margin: 0 0 25px; }
  h1 a {
    color: #171717; }
    h1 a:hover {
      color: #c39570; }
  @media screen and (max-width: 1440px) {
    h1 {
      font-size: 42px; } }
  @media screen and (max-width: 1200px) {
    h1 {
      font-size: 32px; } }
  @media screen and (max-width: 1024px) {
    h1 {
      font-size: 28px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    h1 {
      font-size: 24px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 600px) {
    h1 {
      font-size: 22px; } }

h2 {
  font-family: "bernier_distressedregular";
  font-size: 48px;
  font-weight: normal;
  color: #171717;
  margin: 0 0 25px; }
  h2 a {
    color: #171717; }
    h2 a:hover {
      color: #c39570 !important; }
  @media screen and (max-width: 1440px) {
    h2 {
      font-size: 42px; } }
  @media screen and (max-width: 1200px) {
    h2 {
      font-size: 30px; } }
  @media screen and (max-width: 1024px) {
    h2 {
      font-size: 24px;
      margin-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    h2 {
      font-size: 22px;
      margin-bottom: 20px; } }
  @media screen and (max-width: 600px) {
    h2 {
      font-size: 20px; } }

h3 {
  font-family: "violetregular";
  font-size: 30px;
  font-weight: normal;
  color: #333333; }
  @media screen and (max-width: 1440px) {
    h3 {
      font-size: 26px; } }
  @media screen and (max-width: 1200px) {
    h3 {
      font-size: 22px; } }

h4 {
  font-family: "Conv_DINPro-Medium";
  font-size: 22px;
  font-weight: normal;
  color: #333333; }
  @media screen and (max-width: 1200px) {
    h4 {
      font-size: 20px; } }
  @media screen and (max-width: 1024px) {
    h4 {
      font-size: 18px; } }
  @media screen and (max-width: 768px) {
    h4 {
      font-size: 16px; } }

h5 {
  font-family: "Conv_DINPro-Medium";
  font-size: 18px;
  font-weight: normal;
  color: #333333; }
  @media screen and (max-width: 1024px) {
    h5 {
      font-size: 16px; } }

h6 {
  font-family: "Conv_DINPro-Medium";
  font-size: 16px;
  font-weight: normal;
  text-transform: uppercase;
  color: #333333; }

p {
  margin: 0 0 15px; }

a {
  color: #333333;
  transition: 300ms;
  text-decoration: none; }
  a:focus {
    color: #333333;
    text-decoration: none; }
  a:hover {
    text-decoration: none;
    color: #c39570; }

textarea,
button,
select,
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"],
input[type="file"],
input[type="submit"] {
  border-radius: 0px;
  -webkit-appearance: none; }

.wrapper, .page {
  position: relative;
  flex: 1 0 auto; }
  .wrapper textarea,
  .wrapper input[type="text"],
  .wrapper input[type="email"],
  .wrapper input[type="search"],
  .wrapper input[type="password"], .page textarea,
  .page input[type="text"],
  .page input[type="email"],
  .page input[type="search"],
  .page input[type="password"] {
    min-height: 52px;
    width: 100%;
    background-color: #fff;
    font-family: "Conv_DINPro";
    font-size: 16px;
    padding: 15px 25px;
    margin-bottom: 15px;
    box-shadow: none;
    border: 1px solid #d0ab8a;
    border-radius: 4px; }
    .wrapper textarea::placeholder,
    .wrapper input[type="text"]::placeholder,
    .wrapper input[type="email"]::placeholder,
    .wrapper input[type="search"]::placeholder,
    .wrapper input[type="password"]::placeholder, .page textarea::placeholder,
    .page input[type="text"]::placeholder,
    .page input[type="email"]::placeholder,
    .page input[type="search"]::placeholder,
    .page input[type="password"]::placeholder {
      color: #a3a3a3; }
  @media screen and (max-width: 1024px) {
    .wrapper textarea,
    .wrapper input[type="text"],
    .wrapper input[type="email"],
    .wrapper input[type="search"],
    .wrapper input[type="password"], .page textarea,
    .page input[type="text"],
    .page input[type="email"],
    .page input[type="search"],
    .page input[type="password"] {
      min-height: 45px;
      padding: 11px 15px; } }

.wrapper .container {
  padding-top: 100px;
  padding-bottom: 100px; }
  @media screen and (max-width: 1440px) {
    .wrapper .container {
      padding-top: 75px;
      padding-bottom: 75px; } }
  @media screen and (max-width: 1280px) {
    .wrapper .container {
      padding-top: 50px;
      padding-bottom: 50px; } }
  @media screen and (max-width: 1024px) {
    .wrapper .container {
      padding-top: 30px;
      padding-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    .wrapper .container {
      padding-top: 25px;
      padding-bottom: 25px; } }

.wrapper h3.beige {
  margin-top: -30px; }

@media screen and (max-width: 1440px) {
  .wrapper h3.beige {
    margin-top: -20px; } }

@media screen and (max-width: 1024px) {
  .wrapper h3.beige {
    margin-bottom: 15px; } }

.container {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto; }
  @media screen and (max-width: 1200px) {
    .container {
      width: 100%; } }

.close {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  right: 8px;
  top: 10px;
  opacity: 1;
  outline: none; }
  .close:before, .close:after {
    content: '';
    width: 20px;
    height: 2px;
    background-color: #c39570;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: -1px auto 0;
    transition: 300ms; }
  .close:before {
    transform: rotate(45deg); }
  .close:after {
    transform: rotate(-45deg); }
  .close:hover:before {
    transform: rotate(135deg); }
  .close:hover:after {
    transform: rotate(45deg); }

.btn {
  display: table;
  height: 50px;
  width: auto !important;
  background-color: transparent !important;
  font-family: "Conv_DINPro-Medium" !important;
  font-size: 16px !important;
  color: #c39570 !important;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 3px !important;
  border: 2px solid #e1cab7;
  padding: 15px 35px !important;
  line-height: 1;
  position: relative;
  transition: 300ms; }
  .btn:focus, .btn:active {
    background-color: transparent !important;
    color: #fff; }
  .btn:hover, .btn.active {
    background-color: #c39570 !important;
    border: 2px solid #c39570;
    color: #fff !important; }
  .btn.btn--w {
    border: 2px solid rgba(255, 255, 255, 0.7);
    color: #fff !important; }
    .btn.btn--w:hover {
      border: 2px solid #c39570 !important; }
  .btn.btn--br {
    background-color: #c39570 !important;
    color: #fff !important;
    border: 2px solid #c39570 !important; }
    .btn.btn--br:hover {
      background-color: #fff !important;
      color: #c39570 !important; }
  .btn.more {
    padding-left: 70px !important;
    padding-right: 90px !important;
    margin: 45px auto 0; }
    .btn.more:after, .btn.more:before {
      content: '\f105';
      font-family: 'fontAwesome';
      font-size: 17px;
      position: absolute;
      top: 46%;
      right: 76px;
      transform: translateY(-50%) rotate(0deg);
      transition: 300ms; }
    .btn.more:after {
      right: 70px; }
    .btn.more:hover:before {
      right: 72px; }
    .btn.more:hover:after {
      right: 64px; }
  @media screen and (max-width: 1440px) {
    .btn.more {
      margin-top: 30px; } }
  @media screen and (max-width: 1024px) {
    .btn {
      height: 45px;
      font-size: 14px !important;
      padding: 13px 25px 12px !important; }
      .btn.more {
        padding-left: 30px !important;
        padding-right: 50px !important;
        margin-top: 20px; }
        .btn.more:before, .btn.more:after {
          font-size: 15px; }
        .btn.more:before {
          right: 38px !important; }
        .btn.more:after {
          right: 30px !important; } }
  @media screen and (max-width: 768px) {
    .btn {
      height: 40px;
      padding: 10px 20px 9px !important; }
      .btn.more {
        margin-top: 15px; }
        .btn.more:after, .btn.more:before {
          top: 50%; } }

@media screen and (max-width: 768px) {
  a.btn {
    padding-top: 11px !important; } }

.check--list {
  list-style: none;
  margin: 0;
  padding: 0; }
  .check--list li {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 5px; }
    .check--list li:before {
      content: '\f00c';
      position: absolute;
      font-family: 'fontAwesome';
      font-size: 18px;
      color: #000;
      left: 0;
      top: 0px; }

.col--location,
.col--phone,
.col--email,
.col--time {
  position: relative;
  padding-left: 20px; }
  .col--location:after,
  .col--phone:after,
  .col--email:after,
  .col--time:after {
    content: '';
    font-family: 'fontAwesome';
    font-size: 18px;
    color: #937258;
    position: absolute;
    top: 0px;
    left: 0; }

.col--location:after {
  content: '\f041';
  font-size: 16px;
  top: 0px; }

.col--phone:after {
  content: '\f095';
  font-size: 16px; }

.col--phone a {
  display: table;
  margin-bottom: 4px; }

.col--email:after {
  content: '\f0e0';
  font-size: 13px;
  top: 4px; }

.col--email a {
  text-decoration: underline; }
  .col--email a:hover {
    text-decoration: none; }

.col--time:after {
  content: '\f017';
  font-size: 14px; }

.title--txt {
  display: table;
  padding: 0 40px;
  margin: 0 auto 35px;
  position: relative; }
  .title--txt:before, .title--txt:after {
    content: '';
    height: 6px;
    width: 27px;
    background-color: #171717;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }
  .title--txt:before {
    left: 0; }
  .title--txt:after {
    right: 0; }
  .title--txt.title--left {
    padding-left: 0;
    margin-left: 0;
    margin-right: 0; }
    .title--txt.title--left:before {
      display: none; }
  .title--txt.wh {
    color: #fff; }
    .title--txt.wh:before, .title--txt.wh:after {
      background-color: #fff; }
  @media screen and (max-width: 1440px) {
    .title--txt {
      padding: 0 30px;
      margin-bottom: 25px; }
      .title--txt:before, .title--txt:after {
        width: 20px;
        height: 3px; } }
  @media screen and (max-width: 1024px) {
    .title--txt {
      padding: 0 25px;
      margin-bottom: 20px; }
      .title--txt:before, .title--txt:after {
        width: 15px; } }
  @media screen and (max-width: 768px) {
    .title--txt {
      padding: 0 20px; }
      .title--txt:before, .title--txt:after {
        width: 10px; } }

.beige {
  color: #c39570; }

.center {
  text-align: center; }

/* header */
header {
  background: url(../img/img-3.jpg) no-repeat center center;
  background-size: cover;
  font-size: 14px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  line-height: 1;
  box-shadow: 0px 0px 20px #171717;
  z-index: 9;
  /* header phones */
  /* header phones */
  /* header logo */
  /* header logo */
  /* header nav */
  /* header nav */
  /* header cart */
  /* header cart */ }
  header .container {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  header > .container {
    height: 100px; }
  header .up {
    background-color: #21201e;
    color: #a8a8a7;
    z-index: 2;
    position: relative; }
    header .up a {
      color: #bdbdbc; }
      header .up a:focus {
        color: #bdbdbc; }
      header .up a:hover {
        color: #c39570; }
    header .up .container {
      height: 30px; }
  header .col--location:after {
    font-size: 14px; }
  header ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  header .h--arr {
    font-family: 'fontAwesome';
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%); }
    header .h--arr:after {
      content: '\f107'; }
  header .phones {
    position: relative;
    white-space: nowrap;
    padding-right: 15px; }
    header .phones:after {
      font-size: 15px;
      top: -1px; }
    header .phones a {
      margin-bottom: 0; }
    header .phones ul {
      display: none;
      background-color: #21201e;
      position: absolute;
      left: 0;
      top: calc(100% + 6px);
      padding: 5px 15px 10px 20px; }
      header .phones ul li {
        display: block;
        margin: 5px 0; }
    header .phones .close {
      display: none; }
  header .logo {
    width: 124px; }
    header .logo img {
      width: 100%; }
  header .nav {
    font-size: 16px; }
    header .nav > ul {
      display: flex; }
      header .nav > ul > li {
        display: flex;
        height: 100px;
        align-items: center;
        margin: 0 22px; }
        header .nav > ul > li > a {
          font-family: "Conv_DINPro-Medium";
          color: #171717;
          padding: 10px 0;
          text-transform: uppercase;
          border-bottom: 2px solid transparent; }
          header .nav > ul > li > a:hover {
            border-bottom: 2px solid #c39570; }
        header .nav > ul > li .nav__block {
          display: none;
          width: 1140px;
          background-color: #171717;
          position: absolute;
          top: 100%;
          left: 0;
          right: 0;
          margin: 0 auto;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); }
          header .nav > ul > li .nav__block .nav__blocks {
            display: flex; }
          header .nav > ul > li .nav__block .nav__item {
            display: inline-block;
            vertical-align: top;
            width: calc(16.66% - 5px);
            font-family: "Conv_DINPro-Medium";
            text-align: center;
            color: #fff;
            padding: 30px 15px; }
            header .nav > ul > li .nav__block .nav__item:first-of-type {
              width: calc(16.66% + 10px);
              padding-left: 30px; }
            header .nav > ul > li .nav__block .nav__item:last-of-type {
              width: calc(16.66% + 10px);
              padding-right: 30px; }
            header .nav > ul > li .nav__block .nav__item .nav__img {
              height: 155px;
              width: 100%;
              overflow: hidden;
              margin-bottom: 15px; }
              header .nav > ul > li .nav__block .nav__item .nav__img img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                transition: 300ms;
                transition-delay: 0.15s; }
            header .nav > ul > li .nav__block .nav__item:hover, header .nav > ul > li .nav__block .nav__item.active {
              background-color: #2e2e2e;
              color: #c39570; }
              header .nav > ul > li .nav__block .nav__item:hover .nav__img img, header .nav > ul > li .nav__block .nav__item.active .nav__img img {
                transform: scale(1.1); }
            header .nav > ul > li .nav__block .nav__item.active {
              color: #c39570; }
        header .nav > ul > li.active > a {
          border-bottom: 2px solid #c39570; }
  header .cart {
    font-size: 16px;
    color: #171717;
    position: relative;
    padding-left: 45px; }
    header .cart:after {
      content: '';
      width: 26px;
      height: 26px;
      background: url(../img/img-2.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%); }
    header .cart .cart__qt {
      display: flex;
      background-color: #fff;
      color: #21201e;
      width: 18px;
      height: 18px;
      font-size: 12px;
      border-radius: 100%;
      align-items: center;
      justify-content: center;
      text-align: center;
      position: absolute;
      top: 40%;
      left: 15px;
      transform: translateY(-70%);
      z-index: 1; }
    header .cart:hover {
      color: #171717; }
  @media screen and (max-width: 1024px) {
    header .up .container {
      height: 25px; }
    header > .container {
      justify-content: center;
      height: 75px; }
    header .logo {
      width: 100px; }
    header .cart {
      position: absolute;
      right: 0px;
      top: 60px; }
      header .cart .cart__sum {
        display: none; }
    header .nav {
      display: none;
      background: url(../img/img-3.jpg) repeat;
      position: fixed;
      max-height: calc(100% - 100px);
      top: 100px;
      left: 0;
      right: 0;
      overflow: auto;
      padding: 10px 15px;
      z-index: 1;
      border-top: 2px solid #21201e;
      border-bottom: 2px solid #21201e; }
      header .nav > ul {
        flex-direction: column; }
        header .nav > ul > li {
          flex-direction: column;
          height: auto;
          margin: 0; }
          header .nav > ul > li .nav__block {
            width: 100%;
            position: relative;
            top: 0;
            left: 0;
            right: 0;
            margin-top: 10px; }
            header .nav > ul > li .nav__block .nav__blocks {
              flex-wrap: wrap;
              justify-content: center;
              padding: 7.5px; }
            header .nav > ul > li .nav__block .nav__item {
              width: 25%;
              padding: 10px 7.5px; }
              header .nav > ul > li .nav__block .nav__item:first-of-type {
                width: 25%;
                padding: 10px 7.5px; }
              header .nav > ul > li .nav__block .nav__item:last-of-type {
                width: 25%;
                padding: 10px 7.5px; }
          header .nav > ul > li > a {
            padding: 8px 0; }
    header .nav--btn {
      width: 35px;
      height: 30px;
      position: absolute;
      left: 10px;
      top: 45px;
      padding: 0;
      margin: 0;
      outline: none;
      float: none;
      z-index: 1; }
      header .nav--btn span {
        position: absolute;
        left: 0;
        right: 0;
        width: 85%;
        height: 3px;
        margin: 0 auto;
        background-color: #21201e;
        transition: 350ms; }
        header .nav--btn span:nth-of-type(1) {
          top: 7px; }
        header .nav--btn span:nth-of-type(2) {
          top: 14px; }
        header .nav--btn span:nth-of-type(3) {
          top: 21px; }
      header .nav--btn.open span:nth-of-type(1) {
        top: 14px;
        transform: rotate(45deg); }
      header .nav--btn.open span:nth-of-type(2) {
        opacity: 0; }
      header .nav--btn.open span:nth-of-type(3) {
        top: 14px;
        transform: rotate(-45deg); } }
  @media screen and (max-width: 768px) {
    header .nav > ul > li .nav__block .nav__item {
      width: 33.33% !important; } }
  @media screen and (max-width: 600px) {
    header .nav > ul > li .nav__block .nav__item {
      width: 50% !important; }
      header .nav > ul > li .nav__block .nav__item .nav__img {
        height: 125px;
        margin-bottom: 10px; }
      header .nav > ul > li .nav__block .nav__item .nav__title {
        font-size: 14px; }
    header .phones {
      display: none;
      background-color: #21201e;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      padding: 15px;
      font-size: 16px;
      z-index: 2; }
      header .phones:after {
        display: none; }
      header .phones .close {
        display: block;
        position: absolute;
        right: 5px;
        top: 5px; }
      header .phones a {
        margin: auto; }
      header .phones ul {
        display: block;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        transform: translate(0);
        padding: 0;
        margin: 0; }
        header .phones ul li {
          margin: 7px 0; }
          header .phones ul li:last-of-type {
            margin-bottom: 0; }
      header .phones .h--arr {
        display: none; }
    header .phones--btn {
      position: absolute;
      top: 52px;
      right: 55px;
      font-family: 'fontAwesome';
      font-size: 20px;
      color: #21201e;
      text-decoration: none !important; }
      header .phones--btn:after {
        content: '\f095';
        font-family: 'fontAwesome'; } }
  @media screen and (max-width: 480px) {
    header .up {
      font-size: 12px; }
      header .up .container {
        padding: 0 5px; }
    header .col--location, header .col--time {
      padding-left: 13px; }
      header .col--location:after, header .col--time:after {
        font-size: 10px;
        top: 1px; } }

/* header */
/* main content */
.modal-open {
  overflow-y: visible; }
  .modal-open .modal {
    overflow: auto; }

.fancybox-enabled {
  overflow: visible; }
  .fancybox-enabled body {
    overflow-x: hidden; }

/* main slider */
.slider {
  margin-bottom: 0px !important; }
  .slider .slick-slide {
    height: calc(100vh - 130px);
    overflow: hidden;
    position: relative;
    outline: none; }
    .slider .slick-slide:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      position: absolute;
      top: 0;
      left: 0; }
    .slider .slick-slide img {
      display: block;
      width: 100%;
      height: auto; }
    .slider .slick-slide .slider__block {
      position: absolute;
      left: 0;
      right: 0;
      top: 45%;
      text-align: center;
      font-size: 18px;
      color: #fff;
      margin: 0 auto;
      transform: translateY(-50%);
      z-index: 1; }
      .slider .slick-slide .slider__block .slider__title {
        font-family: "bernier_distressedregular";
        font-size: 60px;
        margin-bottom: 10px; }
      .slider .slick-slide .slider__block .btn {
        margin: 50px auto 0; }
    .slider .slick-slide > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .slider.dots .slick-dots {
    bottom: 40px; }
  @media screen and (max-width: 1440px) {
    .slider .slick-slide .slider__block .slider__title {
      font-size: 50px; }
    .slider .slick-slide .slider__block .btn {
      margin-top: 30px; }
    .slider.dots .slick-dots {
      bottom: 20px; } }
  @media screen and (max-width: 1024px) {
    .slider .slick-slide {
      height: 400px; }
      .slider .slick-slide .slider__block {
        font-size: 16px; }
        .slider .slick-slide .slider__block .slider__title {
          font-size: 40px; } }
  @media screen and (max-width: 768px) {
    .slider .slick-slide {
      height: 350px; }
      .slider .slick-slide .slider__block {
        padding: 0 10px; }
        .slider .slick-slide .slider__block .slider__title {
          font-size: 30px;
          margin-bottom: 5px; }
        .slider .slick-slide .slider__block .btn {
          margin-top: 25px; }
    .slider.dots .slick-dots {
      bottom: 10px; } }
  @media screen and (max-width: 600px) {
    .slider .slick-slide {
      height: 300px; }
      .slider .slick-slide .slider__block .slider__title {
        font-size: 24px; }
      .slider .slick-slide .slider__block .btn {
        margin-top: 20px; } }

.dots {
  margin-bottom: 0 !important; }
  .dots .slick-dots {
    display: flex;
    justify-content: center;
    bottom: 30px; }
    .dots .slick-dots li {
      margin: 0px 8px; }
      .dots .slick-dots li button {
        display: flex;
        width: 22px;
        height: 22px;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        padding: 0;
        border-radius: 100%;
        border: 1px solid transparent;
        transition: 300ms; }
        .dots .slick-dots li button:before {
          display: none; }
        .dots .slick-dots li button:after {
          content: '';
          width: 10px;
          height: 10px;
          background-color: #ad795b;
          border-radius: 100%;
          right: 0;
          margin: 0 auto; }
        .dots .slick-dots li button:hover {
          border: 1px solid #fff; }
          .dots .slick-dots li button:hover:after {
            background-color: #fff; }
      .dots .slick-dots li.slick-active button {
        border: 1px solid #fff; }
        .dots .slick-dots li.slick-active button:after {
          background-color: #fff; }
  @media screen and (max-width: 1440px) {
    .dots .slick-dots li {
      margin: 0 5px; }
      .dots .slick-dots li button {
        width: 18px;
        height: 18px; }
        .dots .slick-dots li button:after {
          width: 6px;
          height: 6px; } }
  @media screen and (max-width: 768px) {
    .dots .slick-dots li {
      width: auto;
      height: auto;
      margin: 0 3px; }
      .dots .slick-dots li button {
        width: 15px;
        height: 15px; }
        .dots .slick-dots li button:after {
          width: 5px;
          height: 5px; } }

.arr .slick-arrow {
  width: 74px;
  height: 24px;
  z-index: 1; }
  .arr .slick-arrow:before {
    display: none; }
  .arr .slick-arrow:after {
    content: '';
    background: url(../img/prev.png) no-repeat center center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .arr .slick-arrow.slick-prev {
    left: 60px; }
  .arr .slick-arrow.slick-next {
    right: 60px; }
    .arr .slick-arrow.slick-next:after {
      transform: rotate(-180deg); }

@media screen and (max-width: 1440px) {
  .arr .slick-arrow {
    width: 60px;
    height: 20px; }
    .arr .slick-arrow:after {
      background-size: contain;
      background-position: center center; } }

@media screen and (max-width: 1024px) {
  .arr .slick-arrow {
    width: 50px;
    height: 15px; } }

/* main slider */
/* main about */
.bg--dark {
  background: url(../img/img-11.jpg);
  color: #cccccc; }
  .bg--dark h1 {
    color: #595959; }
    .bg--dark h1:after, .bg--dark h1:before {
      background-color: #595959; }
  .bg--dark h2 {
    color: #fff; }
    .bg--dark h2 a {
      color: #fff; }

.about {
  position: relative; }
  .about .container {
    padding: 110px 15px;
    position: relative;
    z-index: 1; }
  .about .bg--img {
    position: absolute; }
    .about .bg--img.left {
      left: -50px;
      bottom: -150px; }
    .about .bg--img.right {
      right: 0;
      top: 35%; }
  @media screen and (max-width: 1680px) {
    .about .container {
      padding: 80px 15px; } }
  @media screen and (max-width: 1440px) {
    .about .bg--img.left {
      width: 300px; }
    .about .bg--img.right {
      width: 35%; } }
  @media screen and (max-width: 1200px) {
    .about .container {
      padding: 50px 15px; } }
  @media screen and (max-width: 1024px) {
    .about .container {
      padding: 30px 15px; }
    .about .bg--img {
      display: none; } }

.about__block {
  display: flex; }
  .about__block .about__img {
    display: flex;
    background: url(../img/img-15.png) no-repeat center center;
    background-size: contain;
    height: 725px;
    width: calc(50% + 150px);
    justify-content: center;
    align-items: center;
    padding: 100px;
    margin-left: -165px; }
    .about__block .about__img img {
      display: block;
      max-width: 100%; }
  .about__block .right {
    width: 50%;
    padding: 100px 0 80px 15px; }
  .about__block p {
    margin-bottom: 18px; }
  .about__block .btn {
    margin-top: 60px; }
  @media screen and (max-width: 1440px) {
    .about__block .about__img {
      width: calc(50% + 50px);
      height: 580px;
      padding: 75px;
      margin-left: -120px; }
    .about__block .right {
      padding: 75px 0; }
    .about__block .btn {
      margin-top: 40px; } }
  @media screen and (max-width: 1200px) {
    .about__block .about__img {
      width: 50%;
      margin: 0; }
    .about__block .right {
      width: 50%; } }
  @media screen and (max-width: 1024px) {
    .about__block {
      align-items: flex-start; }
      .about__block .about__img {
        height: 450px;
        padding: 50px; }
      .about__block .right {
        padding: 50px 0; } }
  @media screen and (max-width: 768px) {
    .about__block {
      flex-wrap: wrap;
      padding-bottom: 30px; }
      .about__block .about__img {
        width: 350px;
        height: 350px;
        padding: 30px;
        margin: 0 auto 10px; }
      .about__block .right {
        width: 100%;
        padding: 0; }
      .about__block .btn {
        margin-top: 20px; } }
  @media screen and (max-width: 380px) {
    .about__block .about__img {
      width: 300px;
      height: 300px; } }

.advantages {
  display: flex;
  justify-content: space-between; }
  .advantages .advantages__item {
    max-width: 25%;
    text-align: center;
    padding: 0 15px;
    margin-bottom: 20px; }
    .advantages .advantages__item:first-child {
      padding-left: 0; }
    .advantages .advantages__item:last-child {
      padding-right: 0; }
    .advantages .advantages__item .num {
      display: block;
      font-family: "bernier_distressedregular";
      font-size: 48px;
      color: #fff;
      line-height: 1;
      margin-bottom: 12px; }
    .advantages .advantages__item .txt {
      font-family: "Conv_DINPro";
      color: #c39570;
      text-transform: uppercase; }
  @media screen and (max-width: 1200px) {
    .advantages .advantages__item .num {
      font-size: 38px; } }
  @media screen and (max-width: 1024px) {
    .advantages .advantages__item {
      font-size: 14px; } }
  @media screen and (max-width: 768px) {
    .advantages {
      flex-wrap: wrap; }
      .advantages .advantages__item {
        max-width: 100%;
        width: 50%;
        font-size: 12px;
        padding: 0 7.5px !important; } }

/* main about */
/* main items */
.items__slider {
  margin: 0 -15px; }
  .items__slider .slick-track {
    display: flex;
    z-index: 1; }
  .items__slider .slick-slide {
    height: auto;
    margin-bottom: 80px; }
  .items__slider .slick-dots {
    bottom: 3px; }
  .items__slider.arr .slick-arrow {
    top: auto;
    transform: translateY(0);
    bottom: 0; }
    .items__slider.arr .slick-arrow.slick-prev {
      left: 15px; }
    .items__slider.arr .slick-arrow.slick-next {
      right: 15px; }
  .items__slider.hov .slick-arrow {
    z-index: -1; }
  .items__slider.hov .slick-dots {
    z-index: -1; }
  @media screen and (max-width: 1200px) {
    .items__slider {
      margin: 0 -10px; }
      .items__slider .item__img {
        height: 300px; }
      .items__slider.arr .slick-arrow.slick-prev {
        left: 5px; }
      .items__slider.arr .slick-arrow.slick-next {
        right: 5px; } }
  @media screen and (max-width: 1024px) {
    .items__slider .slick-slide {
      margin-bottom: 40px; }
    .items__slider.arr .slick-arrow {
      bottom: 5px; } }
  @media screen and (max-width: 768px) {
    .items__slider {
      margin-left: -7.5px;
      margin-right: -7.5px; }
      .items__slider .slick-slide {
        margin: 0 7.5px 40px; }
      .items__slider.arr .slick-arrow {
        bottom: 3px; } }

.item {
  width: calc(33.33% - 30px);
  margin: 0 15px;
  outline: none;
  position: relative;
  z-index: 1; }
  .item:before {
    content: '';
    background: url(../img/img-41.jpg) no-repeat center center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 360px;
    z-index: -1;
    border-radius: 0px 0px 5px 5px;
    transition: 300ms; }
  .item .item__img {
    display: block;
    height: 360px;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    overflow: hidden; }
    .item .item__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .item .item__info {
    padding: 25px 30px 100px;
    border-radius: 0px 0px 5px 5px; }
    .item .item__info a {
      display: block;
      font-family: "violetregular";
      font-size: 30px;
      text-align: center; }
  .item .item__qt {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    bottom: 30px;
    left: 30px;
    right: 30px; }
  .item .add {
    width: 195px !important;
    position: absolute;
    top: calc(100% - 50px);
    left: 0;
    right: 0;
    opacity: 0;
    margin: 0 auto; }
  .item:hover:before {
    bottom: -80px; }
  .item:hover .add {
    opacity: 1;
    top: 100%; }
  @media screen and (max-width: 1440px) {
    .item .item__info {
      padding-bottom: 90px; }
      .item .item__info a {
        font-size: 24px; } }
  @media screen and (max-width: 1200px) {
    .item {
      margin: 0 10px;
      width: calc(33.33% - 20px); } }
  @media screen and (max-width: 1024px) {
    .item:before {
      top: 0 !important;
      bottom: 0 !important; }
    .item .item__info {
      padding: 20px 15px 150px; }
    .item .item__qt {
      left: 15px;
      right: 15px;
      bottom: 85px; }
    .item .add {
      width: 160px !important;
      top: auto !important;
      bottom: 25px !important;
      opacity: 1; } }
  @media screen and (max-width: 768px) {
    .item {
      width: calc(50% - 15px);
      margin: 0 7.5px 15px; } }
  @media screen and (max-width: 600px) {
    .item {
      width: 100%;
      margin: 0 0 15px; } }
  @media screen and (max-width: 375px) {
    .item .item__img {
      height: 300px; } }

.qt {
  display: flex;
  width: 152px;
  height: 46px;
  background-color: #f2f2f2;
  border-radius: 3px;
  padding: 8px 0; }
  .qt input[type="text"] {
    width: calc(100% - 96px);
    min-height: 30px;
    font-family: "violetregular";
    font-size: 24px;
    text-align: center;
    color: #666666;
    background-color: #f2f2f2;
    border: none;
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    padding: 5px;
    border-radius: 0px !important;
    margin: 0;
    outline: none; }
    .qt input[type="text"]::placeholder {
      color: #666666; }
  .qt .minus, .qt .plus {
    width: 48px;
    position: relative;
    margin: 0 !important;
    outline: none; }
    .qt .minus:before, .qt .minus:after, .qt .plus:before, .qt .plus:after {
      content: '';
      width: 14px;
      height: 4px;
      background-color: #666666;
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: translateY(-50%); }
  .qt .plus:after {
    transform: rotate(-90deg) translate(1px, 0); }
  @media screen and (max-width: 1440px) {
    .qt {
      width: 125px;
      height: 42px; }
      .qt .minus, .qt .plus {
        width: 40px; }
        .qt .minus:after, .qt .plus:after {
          width: 12px;
          height: 2px; }
      .qt input[type="text"] {
        width: calc(100% - 80px);
        min-height: 26px;
        font-size: 20px; } }
  @media screen and (max-width: 1024px) {
    .qt {
      width: 100px;
      height: 40px;
      padding: 5px 0; }
      .qt .plus, .qt .minus {
        width: 30px; }
        .qt .plus:after, .qt .minus:after {
          width: 10px; }
        .qt .plus:before, .qt .minus:before {
          width: 10px; }
      .qt input[type="text"] {
        width: calc(100% - 60px); } }

.price {
  font-family: "violetregular";
  font-size: 36px;
  color: #990000; }
  .price .sm--price {
    font-family: "Conv_DINPro-Medium";
    font-size: 18px;
    color: #171717; }
  @media screen and (max-width: 1440px) {
    .price {
      font-size: 30px; } }
  @media screen and (max-width: 1024px) {
    .price {
      font-size: 24px; } }

.add {
  font-family: "Conv_DINPro" !important;
  position: relative;
  padding-right: 60px !important;
  margin: 0 auto; }
  .add:after {
    content: '';
    width: 18px;
    height: 18px;
    background: url(../img/img-42.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 35px;
    transform: translateY(-50%); }
  .add:hover:after {
    background: url(../img/img-52.png) no-repeat center center;
    background-size: contain; }
  @media screen and (max-width: 1024px) {
    .add {
      padding-right: 50px !important; }
      .add:after {
        top: 45%;
        right: 22px; } }

/* main items */
/* main catalog */
.bg {
  position: relative; }
  .bg > img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    object-position: center center;
    z-index: -1; }

.radius {
  border-radius: 5px;
  overflow: hidden; }

.cat__block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px -30px; }
  .cat__block .cat__item {
    height: 290px;
    width: calc(33.33% - 30px);
    position: relative;
    margin: 0 15px 30px; }
    .cat__block .cat__item:before {
      content: '';
      position: absolute;
      background-color: #000;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.2;
      transition: 300ms; }
    .cat__block .cat__item:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      border-radius: 5px;
      border: 2px solid rgba(195, 149, 112, 0.7);
      transition: 300ms;
      opacity: 0; }
    .cat__block .cat__item.h {
      height: 610px; }
    .cat__block .cat__item.w {
      width: calc(66.66% - 30px); }
    .cat__block .cat__item .cat__title {
      font-family: "violetregular";
      font-size: 36px;
      color: #fff;
      position: absolute;
      left: 15px;
      right: 15px;
      bottom: 25px;
      transition: 300ms; }
      .cat__block .cat__item .cat__title .title--txt {
        margin: 0 auto; }
        .cat__block .cat__item .cat__title .title--txt:after, .cat__block .cat__item .cat__title .title--txt:before {
          background-color: #fff; }
    .cat__block .cat__item img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .cat__block .cat__item:hover:before {
      opacity: 0.5; }
    .cat__block .cat__item:hover:after {
      top: 10px;
      right: 10px;
      left: 10px;
      bottom: 10px;
      opacity: 1; }
    .cat__block .cat__item:hover .cat__title {
      bottom: 50%;
      transform: translateY(50%); }
  .cat__block .right {
    display: flex;
    justify-content: space-between;
    width: calc(66.66% - 30px);
    flex-wrap: wrap;
    margin: 0 15px; }
    .cat__block .right .cat__item {
      width: calc(50% - 15px);
      margin-left: 0;
      margin-right: 0; }
      .cat__block .right .cat__item.w {
        width: 100%;
        margin-left: 0;
        margin-right: 0; }
  @media screen and (max-width: 1200px) {
    .cat__block .cat__item {
      width: calc(33.33% - 20px);
      margin: 0 10px 20px; }
      .cat__block .cat__item.w {
        width: calc(66.66% - 20px);
        margin: 0 10px 20px; }
    .cat__block .right {
      width: calc(66.66% - 20px);
      margin: 0 10px; }
      .cat__block .right .cat__item {
        width: calc(50% - 10px); } }
  @media screen and (max-width: 1024px) {
    .cat__block {
      margin: 0 -7.5px; }
      .cat__block .cat__item {
        width: calc(33.33% - 15px);
        margin: 0 7.5px 15px; }
        .cat__block .cat__item.w {
          width: calc(66.66% - 15px);
          margin: 0 7.5px 15px; }
        .cat__block .cat__item.h {
          height: 595px; }
      .cat__block .right .cat__item {
        margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .cat__block .cat__item {
      height: 220px; }
      .cat__block .cat__item.h {
        height: 460px; }
      .cat__block .cat__item .cat__title {
        font-size: 26px; } }
  @media screen and (max-width: 600px) {
    .cat__block {
      justify-content: center;
      margin-bottom: -15px; }
      .cat__block .cat__item {
        width: calc(50% - 15px); }
        .cat__block .cat__item.h {
          width: 100%; }
        .cat__block .cat__item.w {
          width: 100%; }
      .cat__block .right {
        width: calc(100% - 15px);
        margin: 0 7.5px 15px; }
        .cat__block .right .cat__item {
          width: calc(50% - 7.5px);
          margin: 0 0 15px; }
          .cat__block .right .cat__item:last-of-type {
            margin-bottom: 0; } }
  @media screen and (max-width: 480px) {
    .cat__block {
      margin: 0 0 -15px; }
      .cat__block .cat__item {
        width: 100%;
        margin: 0 0 15px; }
        .cat__block .cat__item.w {
          margin: 0 0 15px; }
      .cat__block .right {
        width: 100%;
        margin: 0 0 15px; }
        .cat__block .right .cat__item {
          width: 100%; }
          .cat__block .right .cat__item.w {
            width: 100%; } }

/* main catalog */
/* main reviews */
.bg--layer:before {
  content: '';
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1; }

.bg--layer > img {
  z-index: -2; }

.reviews {
  padding-bottom: 90px; }
  .reviews .container {
    padding-bottom: 0; }
  .reviews .title--txt a {
    color: #fff; }
  .reviews .beige {
    margin-bottom: 45px; }
  @media screen and (max-width: 1440px) {
    .reviews {
      padding-bottom: 75px; }
      .reviews .beige {
        margin-bottom: 30px; } }
  @media screen and (max-width: 1200px) {
    .reviews {
      padding-bottom: 50px; } }
  @media screen and (max-width: 1024px) {
    .reviews {
      padding-bottom: 30px; }
      .reviews .beige {
        margin-bottom: 20px; } }

.reviews__slider {
  padding-bottom: 60px; }
  .reviews__slider .slick-list {
    padding: 0 28% !important; }
  .reviews__slider .slick-track {
    display: flex; }
  .reviews__slider .reviews__item {
    height: auto;
    background-color: #fff;
    color: #333333;
    outline: none;
    padding: 20px 40px 10px;
    margin: 0 15px;
    opacity: 0.7; }
    .reviews__slider .reviews__item .reviews__port {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px; }
    .reviews__slider .reviews__item .reviews__img {
      width: 90px;
      height: 90px;
      overflow: hidden;
      border-radius: 100%; }
      .reviews__slider .reviews__item .reviews__img img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center center; }
    .reviews__slider .reviews__item .reviews__right {
      width: calc(100% - 90px);
      padding-left: 20px;
      color: #999999;
      padding-top: 15px; }
    .reviews__slider .reviews__item h3 {
      margin: 0 0 10px; }
    .reviews__slider .reviews__item.slick-center {
      opacity: 1; }
  .reviews__slider.dots .slick-dots {
    bottom: 0; }
  .reviews__slider.arr .slick-arrow {
    top: auto;
    bottom: 0;
    transform: translateY(0); }
    .reviews__slider.arr .slick-arrow.slick-prev {
      left: 50%;
      margin-left: -570px; }
    .reviews__slider.arr .slick-arrow.slick-next {
      right: 50%;
      margin-right: -570px; }
  @media screen and (max-width: 1680px) {
    .reviews__slider .slick-list {
      padding: 0 22% !important; } }
  @media screen and (max-width: 1440px) {
    .reviews__slider .slick-list {
      padding: 0 20% !important; }
    .reviews__slider .reviews__item {
      margin: 0 10px; } }
  @media screen and (max-width: 1366px) {
    .reviews__slider .slick-list {
      padding: 0 15% !important; } }
  @media screen and (max-width: 1200px) {
    .reviews__slider {
      padding-bottom: 40px; }
      .reviews__slider .slick-list {
        padding: 0 10% !important; }
      .reviews__slider.arr .slick-arrow.slick-prev {
        left: 15px;
        margin: 0; }
      .reviews__slider.arr .slick-arrow.slick-next {
        right: 15px;
        margin: 0; } }
  @media screen and (max-width: 1024px) {
    .reviews__slider .slick-list {
      padding: 0 !important; }
    .reviews__slider .slick-slide {
      margin: 0 15px; }
    .reviews__slider.arr .slick-arrow {
      bottom: 5px; }
    .reviews__slider .reviews__item {
      padding: 20px; }
      .reviews__slider .reviews__item p {
        margin-bottom: 10px; } }
  @media screen and (max-width: 600px) {
    .reviews__slider .reviews__item .reviews__img {
      width: 80px;
      height: 80px; }
    .reviews__slider .reviews__item .reviews__right {
      width: calc(100% - 80px);
      padding: 10px 0 0 10px; } }

/* main reviews */
/* main news */
.news {
  margin-bottom: -185px; }
  .news .container {
    padding-bottom: 185px; }
  .news .beige {
    margin-bottom: 45px; }
  @media screen and (max-width: 1440px) {
    .news .container {
      padding-bottom: 150px; }
    .news .beige {
      margin-bottom: 30px; } }
  @media screen and (max-width: 1200px) {
    .news {
      margin-bottom: 0; }
      .news .container {
        padding-bottom: 100px; }
      .news .beige {
        margin-bottom: 20px; } }
  @media screen and (max-width: 1024px) {
    .news .beige {
      margin-bottom: 15px; }
    .news .container {
      padding-bottom: 30px; } }
  @media screen and (max-width: 768px) {
    .news .container {
      padding-bottom: 10px; } }

.news__block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .news__block .news__item {
    display: flex;
    width: calc(50% - 15px);
    flex-direction: column;
    background-color: #fff;
    color: #474747;
    transition: 0ms; }
    .news__block .news__item .news__img {
      height: 250px;
      position: relative; }
      .news__block .news__item .news__img:after {
        content: '';
        background-color: rgba(0, 0, 0, 0.3);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        transition: 300ms;
        opacity: 0; }
      .news__block .news__item .news__img img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
        transition: 300ms; }
      .news__block .news__item .news__img .btn {
        display: block;
        width: 195px !important;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);
        margin: 0 auto;
        z-index: 1;
        opacity: 0; }
        .news__block .news__item .news__img .btn.more {
          padding-left: 35px !important;
          padding-right: 50px !important; }
          .news__block .news__item .news__img .btn.more:after {
            right: 35px; }
          .news__block .news__item .news__img .btn.more:before {
            right: 42px; }
    .news__block .news__item .news__info {
      flex: 1 0 auto;
      border: 1px solid #ebebeb;
      border-top: none;
      padding: 35px 30px 25px;
      transition: 300ms; }
    .news__block .news__item .date {
      display: block;
      color: #999999;
      margin: 0 0 20px; }
    .news__block .news__item p {
      margin-bottom: 10px; }
    .news__block .news__item h3 {
      margin: 0 0 10px;
      transition: 300ms; }
    .news__block .news__item:hover {
      color: #efefef; }
      .news__block .news__item:hover h3 {
        color: #c39570; }
      .news__block .news__item:hover .news__img:after {
        opacity: 1; }
      .news__block .news__item:hover .news__img .btn {
        opacity: 1; }
      .news__block .news__item:hover .news__info {
        background-color: #171717;
        border: 1px solid #171717;
        border-top: none; }
  @media screen and (max-width: 1200px) {
    .news__block .news__item {
      width: calc(50% - 10px); } }
  @media screen and (max-width: 1024px) {
    .news__block .news__item .news__img {
      height: 220px; }
      .news__block .news__item .news__img .btn {
        width: 170px !important; }
    .news__block .news__item .news__info {
      padding: 25px; }
    .news__block .news__item .date {
      margin-bottom: 15px; } }
  @media screen and (max-width: 768px) {
    .news__block .news__item .news__img {
      height: 175px; }
    .news__block .news__item .news__info {
      padding: 20px 20px 15px; }
    .news__block .news__item .date {
      margin-bottom: 10px; } }
  @media screen and (max-width: 480px) {
    .news__block .news__item {
      width: 100%;
      margin: 0 0 15px; }
      .news__block .news__item:last-of-type {
        margin-bottom: 0; } }

/* main news */
/* main instagramm */
.inst .container {
  padding: 110px 15px 0; }

.inst h2 {
  margin-bottom: 20px; }
  .inst h2 a {
    font-family: "violetregular";
    color: #c39570; }

.inst .title--left {
  padding-right: 0;
  padding-left: 40px;
  margin-left: 140px; }
  .inst .title--left:before {
    display: block; }
  .inst .title--left:after {
    display: none; }

@media screen and (max-width: 1440px) {
  .inst .title--left {
    padding-left: 30px;
    margin-left: 75px; }
  .inst .container {
    padding-top: 100px; } }

@media screen and (max-width: 1200px) {
  .inst .container {
    padding-top: 75px; } }

@media screen and (max-width: 1024px) {
  .inst .container {
    padding-top: 50px; }
  .inst .title--left {
    padding-left: 20px;
    margin-left: 0; } }

@media screen and (max-width: 768px) {
  .inst .container {
    padding-top: 30px; }
  .inst h2 {
    margin-bottom: 15px; } }

.inst__slider {
  background-color: #171717;
  padding: 30px 15px; }
  .inst__slider .slick-slide {
    height: 285px;
    position: relative;
    margin: 0 15px; }
    .inst__slider .slick-slide img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .inst__slider .slick-slide:before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      transition: 300ms;
      z-index: 1;
      opacity: 0; }
    .inst__slider .slick-slide:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid rgba(195, 149, 112, 0.8);
      transition: 300ms;
      opacity: 0;
      z-index: 1;
      border-radius: 5px; }
    .inst__slider .slick-slide i {
      font-size: 36px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 15px;
      right: 15px;
      text-align: center;
      transform: translateY(-50%);
      transition: 300ms;
      z-index: 1;
      opacity: 0; }
    .inst__slider .slick-slide:hover:after {
      top: 10px;
      left: 10px;
      right: 10px;
      bottom: 10px;
      opacity: 1; }
    .inst__slider .slick-slide:hover:before {
      opacity: 1; }
    .inst__slider .slick-slide:hover i {
      opacity: 1; }
  @media screen and (max-width: 1440px) {
    .inst__slider .slick-slide {
      margin: 0 10px; } }
  @media screen and (max-width: 1200px) {
    .inst__slider {
      margin: 0; }
      .inst__slider .slick-slide {
        margin: 0 10px; }
        .inst__slider .slick-slide:after {
          opacity: 1;
          top: 10px;
          left: 10px;
          right: 10px;
          bottom: 10px; }
        .inst__slider .slick-slide:before {
          opacity: 1; }
        .inst__slider .slick-slide i {
          font-size: 26px;
          opacity: 1; } }
  @media screen and (max-width: 768px) {
    .inst__slider .slick-slide {
      height: 250px; } }

/* main instagramm */
.map {
  height: 450px; }
  @media screen and (max-width: 1440px) {
    .map {
      height: 400px; } }
  @media screen and (max-width: 1280px) {
    .map {
      height: 350px; } }
  @media screen and (max-width: 1024px) {
    .map {
      height: 300px; } }
  @media screen and (max-width: 768px) {
    .map {
      height: 250px; } }

/* main content */
/* page content */
.page .news1 .date, .page .news .date {
  color: #c39570; }

.page .page_nav .active {
  color: #c39570; }

.page #ask-form {
  max-width: 350px; }

.page .write {
  color: #c39570;
  text-decoration: none; }
  .page .write:hover {
    text-decoration: underline; }

.page .back_link {
  margin: 25px 0; }

.page p a {
  color: #c39570;
  text-decoration: underline; }
  .page p a:hover {
    text-decoration: none; }

.page .h2 a {
  font-size: 16px;
  color: #c39570; }

.page .faq--form {
  background: url(../img/img-41.jpg) repeat; }

.page .faq--form .faq--form__txt,
.page .rev .rev__item .rev__text {
  background: url(../img/img-41.jpg) repeat; }

.page .rev .rev__item .rev__text:after {
  border-right: 12px solid #e9cfac; }

.page .rev .rev__item .rev__user .rev__user--name:after {
  color: #c39570; }

@media screen and (max-width: 1024px) {
  .page #photos-list td img {
    width: 100%;
    height: auto; } }

@media screen and (max-width: 480px) {
  .page p img, .page p strong img, .page h2 img, .page h3 img, .page h4 img, .page h5 img, .page h6 img, .page p a img, .page strong img {
    width: 100% !important;
    height: auto !important;
    display: block !important;
    float: none !important;
    margin: 0 0 10px 0 !important; }
  .page #page-text p a img {
    width: auto !important;
    max-width: 100% !important; }
  .page #photos-list td {
    width: 50%;
    display: inline-block;
    vertical-align: top; } }

/* breadcrumbs */
.breadcrumbs {
  font-size: 14px;
  margin-bottom: 60px; }
  .breadcrumbs .container {
    display: flex;
    min-height: 195px;
    flex-direction: column;
    justify-content: center;
    align-items: center; }
  .breadcrumbs.bg > img {
    object-position: center top; }
  .breadcrumbs ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin-bottom: 20px; }
    .breadcrumbs ul li {
      padding: 5px 0px;
      color: #cccccc;
      display: inline-block;
      vertical-align: top; }
      .breadcrumbs ul li a {
        color: #c39570;
        text-decoration: none; }
        .breadcrumbs ul li a:hover {
          color: #cccccc; }
      .breadcrumbs ul li:after {
        content: '\f105';
        font-family: 'fontAwesome';
        margin: 2px 7px 0px 7px;
        color: #c39570;
        display: inline-block;
        vertical-align: top; }
      .breadcrumbs ul li:last-of-type:after {
        display: none; }
  .breadcrumbs h1 {
    margin: 0; }
  @media screen and (max-width: 1440px) {
    .breadcrumbs {
      margin-bottom: 50px; } }
  @media screen and (max-width: 1200px) {
    .breadcrumbs {
      margin-bottom: 30px; } }
  @media screen and (max-width: 1024px) {
    .breadcrumbs .container {
      min-height: 100px;
      padding: 15px; }
    .breadcrumbs ul {
      margin-bottom: 10px; }
      .breadcrumbs ul li {
        padding: 2px 0; }
        .breadcrumbs ul li:after {
          margin: 2px 5px 0px 5px; } }

/* breadcrumbs */
/* page about */
.page .about {
  margin-top: -60px; }

.page .icon {
  margin-top: 30px;
  max-width: 100%; }

.page .beige {
  margin-top: -30px; }

.page .about__block {
  margin-bottom: 20px; }

@media screen and (max-width: 1440px) {
  .page .about {
    margin-top: -50px; } }

@media screen and (max-width: 1200px) {
  .page .about {
    margin-top: -30px; } }

@media screen and (max-width: 1024px) {
  .page .beige {
    margin-top: -20px;
    margin-bottom: 15px; }
  .page .icon {
    margin-top: 10px; } }

/* page about */
/* page catalog */
.page .item__block {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px; }
  .page .item__block .item {
    margin-bottom: 30px; }
    .page .item__block .item:hover {
      z-index: 2; }

@media screen and (max-width: 1200px) {
  .page .item__block {
    margin: 0 -10px; }
    .page .item__block .item {
      margin-bottom: 20px; } }

@media screen and (max-width: 768px) {
  .page .item__block {
    margin: 0 -7.5px; } }

@media screen and (max-width: 600px) {
  .page .item__block {
    margin: 0; } }

.cat--unit {
  display: flex;
  flex-wrap: wrap;
  background: url(../img/img-41.jpg) repeat;
  padding-bottom: 20px; }
  .cat--unit .left {
    display: flex;
    width: 50%;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; }
  .cat--unit .right {
    width: 50%;
    position: relative;
    border-radius: 0px 5px 5px 0; }
  .cat--unit .cat--unit__block {
    padding: 55px 30px 25px;
    border-bottom: 1px solid #ffffff; }
    .cat--unit .cat--unit__block:last-of-type {
      border-bottom: none; }
  .cat--unit .cat--unit__price {
    display: flex;
    margin-bottom: 20px; }
  .cat--unit h4 {
    margin: 0 0 15px; }
  .cat--unit .qt {
    height: 50px; }
  .cat--unit .price {
    display: block;
    margin-bottom: 28px; }
    .cat--unit .price span {
      font-family: "Conv_DINPro-Medium";
      font-size: 24px;
      color: #333333; }
  .cat--unit .btn {
    margin: 0 0 0 28px; }
  .cat--unit .att {
    width: 100%;
    color: #565049;
    padding: 0 15px;
    margin: 15px 0 0 0; }
    .cat--unit .att span {
      color: red; }
  @media screen and (max-width: 1024px) {
    .cat--unit .cat--unit__block {
      padding: 30px 20px 15px; }
    .cat--unit .price span {
      font-size: 20px; }
    .cat--unit .qt {
      height: 45px; }
    .cat--unit .att {
      bottom: 30px; } }
  @media screen and (max-width: 768px) {
    .cat--unit {
      flex-wrap: wrap; }
      .cat--unit .left {
        width: 100%;
        margin-bottom: 15px; }
      .cat--unit .right {
        width: 100%;
        padding: 0 0 10px;
        border-radius: 5px; }
      .cat--unit .att {
        position: relative;
        left: 0;
        bottom: 0; }
      .cat--unit .price {
        margin-bottom: 20px; }
      .cat--unit .cat--unit__block {
        padding: 20px 20px 15px; }
      .cat--unit .qt {
        height: 40px; }
      .cat--unit .btn {
        margin-left: 15px; } }

.slider__nav {
  width: 100%;
  order: 2;
  padding-right: 80px;
  margin-top: 15px; }
  .slider__nav .slick-slide {
    height: 83px;
    outline: none;
    position: relative;
    margin-right: 10px; }
    .slider__nav .slick-slide:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 3px solid #c39570;
      transition: 300ms;
      opacity: 0; }
    .slider__nav .slick-slide img {
      widtH: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
    .slider__nav .slick-slide:hover:after, .slider__nav .slick-slide.slick-current:after {
      opacity: 1; }
  .slider__nav .slick-arrow {
    border-radius: 5px;
    overflow: hidden;
    transition: 300ms; }
    .slider__nav .slick-arrow.slick-prev {
      display: none; }
    .slider__nav .slick-arrow.slick-next {
      width: 83px;
      height: 66px;
      background: url(../img/img-3.jpg) repeat;
      z-index: 1;
      left: auto;
      right: 5px;
      top: 50%;
      transform: translateY(-50%) rotate(-90deg); }
      .slider__nav .slick-arrow.slick-next:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url("../img/down.png") no-repeat center center; }
    .slider__nav .slick-arrow:before {
      display: none; }
    .slider__nav .slick-arrow:hover {
      opacity: 0.7; }
  @media screen and (max-width: 768px) {
    .slider__nav .slick-arrow.slick-next {
      right: 0; } }
  @media screen and (max-width: 600px) {
    .slider__nav {
      padding-right: 60px; }
      .slider__nav .slick-slide {
        height: 65px; }
      .slider__nav .slick-arrow.slick-next {
        width: 65px;
        height: 50px; }
        .slider__nav .slick-arrow.slick-next:after {
          background-size: 25px; } }

.slider__for {
  width: 100%;
  order: 1; }
  .slider__for .slick-slide.radius {
    border-radius: 5px 0px 0px 5px; }
  .slider__for .slick-slide img {
    width: 100%; }
  @media screen and (max-width: 1024px) {
    .slider__for .slick-slide {
      height: 380px; } }
  @media screen and (max-width: 768px) {
    .slider__for .slick-slide {
      height: 360px; }
      .slider__for .slick-slide.radius {
        border-radius: 5px; } }
  @media screen and (max-width: 600px) {
    .slider__for {
      width: 100%; } }
  @media screen and (max-width: 480px) {
    .slider__for .slick-slide {
      height: 290px; } }

.similar {
  margin-top: 115px; }
  .similar .items__slider.dots .slick-dots li button:hover {
    border: 1px solid #171717; }
    .similar .items__slider.dots .slick-dots li button:hover:after {
      background-color: #171717; }
  .similar .items__slider.dots .slick-dots li.slick-active button {
    border: 1px solid #171717; }
    .similar .items__slider.dots .slick-dots li.slick-active button:after {
      background-color: #171717; }
  .similar .items__slider.arr .slick-arrow:after {
    background: url(../img/prev1.png) no-repeat center center; }
  @media screen and (max-width: 1440px) {
    .similar {
      margin-top: 100px; }
      .similar .items__slider.arr .slick-arrow:after {
        background-size: contain;
        background-position: center center; } }
  @media screen and (max-width: 1200px) {
    .similar {
      margin-top: 75px; } }
  @media screen and (max-width: 1024px) {
    .similar {
      margin-top: 50px; } }
  @media screen and (max-width: 768px) {
    .similar {
      margin-top: 30px; } }

/* page catalog */
/* page basket */
.basket {
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
  border-collapse: collapse; }
  .basket tr {
    border-bottom: 1px solid #fff; }
    .basket tr:last-child {
      border-bottom: none; }
    .basket tr th {
      min-width: 135px;
      font-weight: normal;
      background: url(../img/img-48.jpg) repeat;
      padding: 15px;
      text-align: left; }
    .basket tr td {
      background: url(../img/img-51.jpg) repeat;
      padding: 15px; }
  .basket .b--head .b--name {
    width: 580px;
    padding-left: 160px; }
  .basket .b--head .b--price-t {
    background: url(../img/img-49.jpg) repeat;
    text-align: center; }
  .basket .b--head .b--qt {
    text-align: center; }
  .basket .b--head .b--close {
    min-width: 100px; }
  .basket .b--body {
    background: url(../img/img-41.jpg) repeat; }
    .basket .b--body .b--price-t {
      background: url(../img/img-50.jpg) repeat;
      text-align: center; }
  .basket .basket__item {
    display: flex;
    align-items: center; }
    .basket .basket__item .basket__img {
      width: 100px; }
      .basket .basket__item .basket__img img {
        display: block;
        width: 100%; }
    .basket .basket__item .basket__info {
      width: calc(100% - 100px);
      padding-left: 25px; }
      .basket .basket__item .basket__info .basket__title {
        font-family: "violetregular";
        font-size: 36px;
        color: #171717; }
  .basket .qt {
    margin: 0 auto; }
  .basket .close {
    position: relative;
    top: 0;
    right: 0;
    margin: 0 auto; }
  @media screen and (max-width: 1024px) {
    .basket .basket__item .basket__info {
      padding-left: 15px; }
      .basket .basket__item .basket__info .basket__title {
        font-size: 28px; } }
  @media screen and (max-width: 768px) {
    .basket tbody {
      display: block; }
    .basket tr {
      display: flex; }
    .basket .b--head {
      display: none; }
    .basket .b--body {
      display: flex;
      position: relative; }
    .basket .b--name {
      width: 100%;
      padding-right: 40px;
      padding-bottom: 70px; }
    .basket .b--price {
      display: none; }
    .basket .b--qt {
      position: absolute;
      bottom: 15px;
      left: 15px;
      padding: 0; }
    .basket .b--price-t {
      background: transparent !important;
      padding: 0;
      position: absolute;
      bottom: 22px;
      left: 150px; }
    .basket .b--close {
      padding: 0;
      position: absolute;
      right: 10px;
      top: 12px; }
    .basket .basket__item .basket__info .basket__title {
      font-size: 22px; } }

.formalization {
  background: url(../img/img-41.jpg) repeat;
  padding: 85px 0; }
  .formalization .form__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 15px; }
    .formalization .form__row.down .check:last-of-type {
      margin-right: 50px; }
  .formalization .form__item {
    widtH: calc(50% - 15px); }
    .formalization .form__item.right .check {
      margin: 18px 0 28px 0; }
      .formalization .form__item.right .check:last-of-type {
        margin-left: 100px; }
  .formalization h5 {
    width: 100%;
    margin: 0 0 25px; }
  .formalization .form__inp {
    display: flex;
    justify-content: space-between; }
    .formalization .form__inp input[type="text"] {
      width: calc(33.33% - 10px); }
  .formalization .check:first-of-type {
    margin-right: 105px; }
  .formalization .check.sec {
    margin-right: 50px; }
  .formalization .price--center {
    display: block;
    text-align: center; }
  .formalization .btn {
    margin: 30px auto 0; }
  @media screen and (max-width: 1024px) {
    .formalization .check:first-of-type {
      margin-right: 50px; }
    .formalization .form__item {
      width: calc(50% - 7.5px); }
      .formalization .form__item.right .check {
        margin-bottom: 22px; }
        .formalization .form__item.right .check:last-of-type {
          margin-left: 30px; } }
  @media screen and (max-width: 768px) {
    .formalization {
      padding: 50px 0; }
      .formalization .form__item {
        width: 100%; }
        .formalization .form__item.right .check:last-of-type {
          margin-bottom: 15px; }
      .formalization h5 {
        margin: 0 0 15px; }
      .formalization .check:first-of-type {
        margin-right: 0; } }
  @media screen and (max-width: 600px) {
    .formalization {
      padding: 30px 0; }
      .formalization .check {
        display: table;
        margin: 0 0 10px !important; }
      .formalization .btn {
        margin-top: 20px; } }

.del {
  display: none; }

.check {
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
  user-select: none;
  margin-bottom: 20px; }
  .check input {
    display: none; }
    .check input:checked + span:after {
      opacity: 1; }
  .check span {
    display: table;
    position: relative;
    padding-left: 22px; }
    .check span:before {
      content: '';
      width: 12px;
      height: 12px;
      background-color: #fff;
      border-radius: 100%;
      position: absolute;
      top: 3px;
      left: 0px;
      border: 1px solid #d2d2d1; }
    .check span:after {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: #c79c7a;
      top: 7px;
      left: 4px;
      border-radius: 100%;
      transition: 300ms;
      opacity: 0; }
  @media screen and (max-width: 768px) {
    .check {
      margin-bottom: 15px; } }

.basket--price {
  display: block;
  text-align: right;
  margin: 18px 140px 50px; }
  @media screen and (max-width: 1024px) {
    .basket--price {
      margin: 15px 0 30px; } }

/* page basket */
/* page contacs */
.socials {
  display: flex; }
  .socials li {
    margin-right: 25px; }
    .socials li a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      font-size: 22px;
      background-color: #3a3a3a;
      color: #9d9d9d;
      border-radius: 100%; }
      .socials li a:hover {
        background-color: #907056;
        color: #fff; }
  @media screen and (max-width: 768px) {
    .socials li a {
      font-size: 20px; } }
  @media screen and (max-width: 600px) {
    .socials li a {
      width: 40px;
      height: 40px; } }

.cts {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 100px; }
  .cts .cts__item {
    width: calc(50% - 30px); }
    .cts .cts__item h3 {
      margin: 0 0 20px; }
  .cts input[type="text"],
  .cts input[type="email"] {
    min-height: 42px;
    padding: 10px 0;
    border: none;
    outline: none;
    border-radius: 0px;
    border-bottom: 1px solid #d5b59b;
    margin: 20px 0 0 0; }
  .cts textarea {
    height: 120px;
    padding: 20px 20px;
    margin-top: 40px;
    resize: none;
    border-radius: 0px; }
  .cts ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  .cts .cts__list {
    margin-top: 38px; }
    .cts .cts__list li {
      margin: 0 0 20px; }
  .cts .col--location:after {
    font-size: 15px;
    top: 2px; }
  .cts .col--phone:after {
    top: 3px; }
  .cts .col--time:after {
    top: 3px; }
  .cts .socials {
    margin: 30px 0; }
    .cts .socials li a {
      background-color: #c39570;
      color: #e1cab8; }
      .cts .socials li a:hover {
        background-color: #171717;
        color: #bababa; }
  .cts .btn {
    margin-top: 15px; }
  @media screen and (max-width: 1440px) {
    .cts {
      margin-bottom: 75px; } }
  @media screen and (max-width: 1024px) {
    .cts {
      margin-bottom: 50px; }
      .cts .cts__item {
        width: calc(50% - 7.5px); }
      .cts .socials li {
        margin-right: 15px; }
      .cts input[type="text"],
      .cts input[type="email"] {
        min-height: 40px;
        padding: 8px 0;
        margin-top: 15px; }
      .cts textarea {
        height: 100px;
        padding: 10px 15px;
        margin-top: 20px; } }
  @media screen and (max-width: 768px) {
    .cts {
      margin-bottom: 30px; } }
  @media screen and (max-width: 600px) {
    .cts {
      flex-direction: column; }
      .cts .cts__item {
        width: 100%; }
        .cts .cts__item h3 {
          margin-bottom: 15px; }
      .cts .cts__list {
        margin-top: 15px; }
        .cts .cts__list li {
          margin-bottom: 15px; }
      .cts .socials {
        margin-top: 20px; } }
  @media screen and (max-width: 340px) {
    .cts .g-recaptcha {
      width: 280px;
      margin: 0 auto; }
      .cts .g-recaptcha > div {
        transform: scale(0.9);
        transform-origin: 0 0; }
    .cts .btn {
      margin-top: 10px; } }

.cts__form input[type="text"]:first-of-type {
  margin-top: 0; }

/* page contacs */
/* table menu */
.welcome__img {
  display: block;
  width: 100%;
  height: 100%; }
  .welcome__img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center; }

.table--menu {
  font-family: "latoregular";
  background: url(../img/img-41.jpg) repeat;
  padding-top: 68px; }

.table--header {
  height: 68px;
  background: url(../img/img-50.jpg) repeat;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  z-index: 9; }
  .table--header .logo {
    display: flex;
    background-color: #202020;
    width: 255px;
    align-items: center;
    justify-content: center; }
  .table--header .right {
    width: calc(100% - 255px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px; }
  .table--header h1 {
    font-family: "violetregular";
    font-size: 34px;
    margin: 0;
    text-transform: inherit; }
  .table--header .search {
    width: 230px;
    height: 26px;
    position: relative;
    padding-right: 20px;
    border-bottom: 1px solid #202020; }
    .table--header .search input[type="search"] {
      background: transparent;
      width: 100%;
      font-family: "Conv_DINPro";
      font-size: 14px;
      border: none;
      padding: 5px 10px 5px 0;
      outline: none; }
      .table--header .search input[type="search"]::-webkit-search-cancel-button {
        appearance: none; }
    .table--header .search button {
      font-family: 'fontAwesome';
      font-size: 16px;
      background: transparent;
      padding: 0;
      border: none;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%); }
      .table--header .search button:after {
        content: '\f002'; }

.table--content {
  display: flex;
  height: 100%;
  padding-left: 255px; }
  .table--content h2 {
    font-family: "violetregular";
    font-size: 34px;
    margin-bottom: 15px; }
  .table--content > .left {
    position: fixed;
    width: 255px;
    padding-top: 1px;
    left: 0;
    top: 68px;
    bottom: 0;
    background-color: #131313;
    z-index: 1; }
  .table--content > .right {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 20px;
    line-height: 1; }
  .table--content .table--list {
    list-style: none;
    margin: 0;
    padding: 0;
    height: calc(100% - 90px);
    display: flex;
    justify-content: space-between;
    flex-direction: column; }
    .table--content .table--list > li {
      border-bottom: 2px solid #202020; }
      .table--content .table--list > li > a {
        display: flex;
        height: 66px;
        font-family: "latosemibold";
        color: #cecece;
        align-items: center;
        position: relative;
        padding: 10px 40px 10px 65px; }
        .table--content .table--list > li > a:after {
          content: '\f105';
          font-family: 'fontAwesome';
          font-size: 16px;
          color: #616161;
          position: absolute;
          top: 50%;
          right: 20px;
          top: 50%;
          transform: translateY(-50%); }
        .table--content .table--list > li > a img {
          width: 33px;
          height: 29px;
          object-fit: contain;
          object-position: center center;
          position: absolute;
          top: 50%;
          left: 20px;
          transform: translateY(-50%); }
      .table--content .table--list > li.active > a {
        background-color: #333333; }
      .table--content .table--list > li ul {
        width: 182px;
        background-color: #333333;
        position: fixed;
        top: 69px;
        left: 72px;
        bottom: 90px;
        padding: 20px;
        overflow: auto; }
        .table--content .table--list > li ul li {
          margin: 0 0 20px; }
          .table--content .table--list > li ul li a {
            font-family: "latosemibold";
            font-size: 16px;
            color: #cecece; }
            .table--content .table--list > li ul li a:hover {
              color: #cecece; }
          .table--content .table--list > li ul li.active a {
            color: #c39570; }
  .table--content .table--down {
    display: flex;
    align-items: center;
    height: 89px;
    background-color: #333333;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-family: "Conv_DINPro-Italic";
    font-size: 16px;
    color: #cccccc;
    padding: 20px; }
  .table--content .slider {
    margin-bottom: 35px !important; }
    .table--content .slider .slick-arrow {
      display: none !important; }
    .table--content .slider .slick-slide:after {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)); }
    .table--content .slider .slick-slide .slider__block {
      width: 340px;
      font-size: 16px;
      color: #d7d2d0;
      text-align: left;
      position: absolute;
      left: 35px;
      right: 35px;
      margin: 0; }
      .table--content .slider .slick-slide .slider__block .slider__title {
        display: block;
        font-family: "violetregular";
        font-size: 42px;
        color: #ffffff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.7);
        padding: 0 0 20px;
        margin-bottom: 25px;
        line-height: 1.2; }
      .table--content .slider .slick-slide .slider__block p {
        line-height: 1.2; }
        .table--content .slider .slick-slide .slider__block p span {
          font-family: "latoheavy";
          font-size: 22px; }
    .table--content .slider .slick-dots {
      bottom: 20px; }
      .table--content .slider .slick-dots li {
        width: auto;
        height: auto;
        margin: 0 5px; }
        .table--content .slider .slick-dots li button {
          width: 10px;
          height: 10px;
          padding: 0px;
          background-color: #e7dfd8;
          border-radius: 100%;
          transition: 300ms; }
          .table--content .slider .slick-dots li button:before {
            display: none; }
        .table--content .slider .slick-dots li.slick-active button {
          background-color: #9c6f4b; }

.tabke--about {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  padding: 0 35px; }
  .tabke--about .left {
    width: calc(100% - 235px); }
  .tabke--about img {
    max-width: 235px;
    margin-top: 15px; }
  .tabke--about p {
    line-height: 1.4; }
  .tabke--about .btn {
    height: 50px;
    font-family: "latosemibold";
    background-color: #9c6f4b !important;
    font-size: 12px;
    color: #fff !important;
    text-transform: uppercase;
    padding: 15px 30px !important;
    border-radius: 4px !important; }

.table--inside .table--list > li > a {
  width: 72px;
  font-size: 12px;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  padding: 40px 5px 10px; }
  .table--inside .table--list > li > a:after {
    display: none; }
  .table--inside .table--list > li > a img {
    width: 28px;
    height: 25px;
    top: 19px;
    left: 0;
    right: 0;
    margin: 0 auto; }

.table--title {
  display: flex;
  min-height: 82px;
  font-size: 14px;
  color: #333333;
  background-color: rgba(195, 149, 112, 0.3);
  padding: 0px 0 0px 85px;
  position: relative;
  margin-bottom: 10px; }
  .table--title h3 {
    font-family: "violetregular";
    font-size: 24px;
    margin: 0 0 8px; }
  .table--title .icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%); }
    .table--title .icon img {
      width: 100%; }
  .table--title p {
    margin: 0; }
  .table--title .table--txt {
    width: calc(100% - 119px);
    padding: 15px 0 10px; }
  .table--title .time {
    width: 119px;
    background-color: rgba(156, 111, 75, 0.3);
    padding: 50px 10px 15px;
    border-left: 1px solid #e2ceb4;
    position: relative;
    text-align: center; }
    .table--title .time:after {
      content: '\f017';
      font-family: 'fontAwesome';
      font-size: 30px;
      position: absolute;
      left: 10px;
      right: 10px;
      top: 10px;
      color: #423e3b;
      text-align: center; }
    .table--title .time h5 {
      font-family: "violetregular";
      font-size: 18px;
      color: #1e1e1e;
      margin: 0; }

.table--blocks {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px; }
  .table--blocks .table--item {
    width: calc(33.33% - 20px);
    background-color: #ffffff;
    color: #5c5c5c;
    text-align: center;
    position: relative;
    margin: 0 10px 20px; }
    .table--blocks .table--item .table--img {
      height: 157px;
      position: relative; }
      .table--blocks .table--item .table--img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center; }
    .table--blocks .table--item .icon {
      width: 30px;
      position: absolute;
      right: 18px;
      top: 15px; }
      .table--blocks .table--item .icon img {
        width: 100%; }
    .table--blocks .table--item .table--info {
      padding: 18px 20px 60px; }
    .table--blocks .table--item .stiker {
      position: absolute;
      top: 0;
      left: 20px;
      top: 15px;
      line-height: 1; }
      .table--blocks .table--item .stiker span {
        display: block;
        font-family: "latosemibold";
        font-size: 11px;
        color: #ffffff;
        text-transform: uppercase;
        text-align: center;
        padding: 5px 10px;
        margin-bottom: 5px;
        border-radius: 3px; }
        .table--blocks .table--item .stiker span.green {
          background-color: #a8c256; }
        .table--blocks .table--item .stiker span.red {
          background-color: #990000; }
    .table--blocks .table--item h3 {
      font-size: 23px;
      color: #171717;
      text-align: center;
      margin: 0 0 10px; }
    .table--blocks .table--item .price {
      font-weight: bold; }
      .table--blocks .table--item .price span {
        font-size: 18px;
        color: #666666; }
    .table--blocks .table--item .down {
      display: flex;
      justify-content: space-between;
      position: absolute;
      left: 20px;
      right: 20px;
      bottom: 20px;
      padding-top: 15px;
      border-top: 1px solid #d2d2d2; }
      .table--blocks .table--item .down.center {
        justify-content: center; }

.table--modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99; }
  .table--modal:after {
    content: '';
    background-color: #000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    opacity: 0.7; }
  .table--modal .table--modal__block {
    height: 100%;
    background-color: #fff;
    font-size: 16px;
    margin-left: 343px; }
  .table--modal .slider {
    margin: 0 !important; }
    .table--modal .slider .slick-slide {
      height: 50vh; }
  .table--modal .table--modal__img {
    height: 50%; }
    .table--modal .table--modal__img img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; }
  .table--modal .table--modal__info {
    padding: 50px 40px; }
  .table--modal .table--info__down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    border-top: 1px solid #d2d2d2;
    margin-top: 30px; }
  .table--modal .table--time {
    position: relative;
    font-family: "latosemibold";
    color: #c39570;
    padding-left: 30px; }
    .table--modal .table--time:after {
      content: '';
      width: 22px;
      height: 22px;
      background: url(../img/img-72.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%); }
    .table--modal .table--time.table--user:after {
      background: url(../img/img-73.png) no-repeat center center;
      background-size: contain; }
  .table--modal h3 {
    font-family: "violetregular";
    font-size: 30px;
    color: #171717;
    margin: 0 0 15px; }
  .table--modal .price span {
    font-size: 22px;
    color: #666666; }
  .table--modal .close {
    width: 32px;
    height: 32px;
    right: calc(100% - 305px);
    top: 20px; }
    .table--modal .close:before, .table--modal .close:after {
      background-color: #fff;
      width: calc(100% + 5px);
      height: 2px;
      left: 50%;
      margin-left: -60%; }

/* table menu */
/* pagination */
.pagination {
  line-height: 1;
  margin: 10px 0 0; }
  .pagination ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    .pagination ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 10px;
      text-align: center; }
      .pagination ul li a {
        display: block;
        font-family: "violetregular";
        font-size: 30px;
        color: #c39570;
        text-align: center; }
        .pagination ul li a:hover {
          color: #171717;
          text-decoration: underline;
          text-decoration-color: #8b8b8b; }
      .pagination ul li.active a {
        color: #171717;
        text-decoration: underline;
        text-decoration-color: #8b8b8b; }
      .pagination ul li.prev a, .pagination ul li.next a {
        font-size: 24px; }
        .pagination ul li.prev a:after, .pagination ul li.next a:after {
          content: '\f101';
          font-family: 'fontAwesome';
          line-height: 18px;
          margin-top: -5px; }
        .pagination ul li.prev a:hover, .pagination ul li.next a:hover {
          text-decoration: none; }
      .pagination ul li.next {
        margin-left: 15px; }
      .pagination ul li.prev {
        margin-right: 15px; }
        .pagination ul li.prev a:after {
          content: '\f100'; }
  @media screen and (max-width: 1024px) {
    .pagination ul li a {
      font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .pagination ul li {
      margin: 0 8px; }
      .pagination ul li a {
        font-size: 20px; }
      .pagination ul li.prev a:after, .pagination ul li.next a:after {
        font-size: 18px;
        margin-top: -2px; } }

/* pagination */
/* modal */
.modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99; }
  .modal .modal__layer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .modal .modal__block {
    width: 420px;
    background-color: #fff;
    position: absolute;
    padding: 20px;
    left: 0;
    right: 0;
    top: 50%;
    margin: 0 auto;
    transform: translateY(-50%); }
    .modal .modal__block h4 {
      font-size: 20px;
      text-align: center;
      padding: 0 10px;
      margin: 0; }
  .modal form {
    display: block;
    padding-bottom: 130px;
    margin-top: 15px; }
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 52px;
      width: 100%;
      background-color: #fff;
      font-family: "Conv_DINPro";
      font-size: 16px;
      padding: 15px 25px;
      margin-bottom: 15px;
      box-shadow: none;
      border: 1px solid #d0ab8a;
      border-radius: 4px; }
      .modal form textarea::placeholder,
      .modal form input[type="text"]::placeholder,
      .modal form input[type="email"]::placeholder,
      .modal form input[type="search"]::placeholder,
      .modal form input[type="password"]::placeholder {
        color: #a3a3a3; }
    .modal form .g-recaptcha {
      float: right; }
    .modal form .btn {
      float: right;
      margin: 15px 0 0 0; }
  @media screen and (max-width: 1200px) {
    .modal .modal__block h4 {
      font-size: 18px; } }
  @media screen and (max-width: 1024px) {
    .modal form textarea,
    .modal form input[type="text"],
    .modal form input[type="email"],
    .modal form input[type="search"],
    .modal form input[type="password"] {
      min-height: 45px;
      padding: 11px 15px; } }
  @media screen and (max-width: 768px) {
    .modal .modal__block h4 {
      font-size: 16px; } }
  @media screen and (max-width: 500px) {
    .modal .modal__block {
      width: auto;
      left: 10px;
      right: 10px; } }
  @media screen and (max-width: 380px) {
    .modal form .g-recaptcha {
      transform: scale(0.85);
      transform-origin: 100% 50%; } }

.call--btn {
  position: fixed;
  bottom: 100px;
  right: 40px;
  display: table;
  background-color: #ffcc00;
  font-family: 'FontAwesome';
  border-radius: 100%;
  text-decoration: none !important;
  width: 80px;
  height: 80px;
  text-align: center;
  padding: 23px 5px 5px 5px;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.7);
  box-sizing: border-box;
  outline: none !important;
  z-index: 1; }
  .call--btn:hover {
    animation-name: phone;
    animation-iteration-count: 1;
    animation-duration: 0.7s; }
  .call--btn i {
    display: block;
    font-size: 35px;
    color: #fff; }
  .call--btn:after, .call--btn:before {
    content: '';
    width: 90%;
    height: 90%;
    background-color: transparent;
    border: 1px solid #ffcc00;
    position: absolute;
    top: 5%;
    left: 5%;
    border-radius: 100%;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-name: ring;
    outline: none; }
  .call--btn:after {
    animation-delay: 0.6s; }
  .call--btn:before {
    animation-delay: 0.8s;
    border: 1px solid #d9af04; }
  @media screen and (max-width: 1440px) {
    .call--btn {
      margin-right: 0;
      right: 35px;
      bottom: 75px; } }
  @media screen and (max-width: 991px) {
    .call--btn {
      width: 50px;
      height: 50px;
      padding-top: 15px;
      right: 15px;
      bottom: 80px; }
      .call--btn i {
        font-size: 24px; } }
  @media screen and (max-width: 768px) {
    .call--btn {
      bottom: 50px; } }

@keyframes ring {
  0% {
    opacity: 1;
    transform: scale(1); }
  100% {
    opacity: 0.1;
    transform: scale(1.7); } }

@keyframes phone {
  20% {
    transform: rotate(-30deg); }
  80% {
    transform: rotate(390deg); }
  100% {
    transform: rotate(360deg); } }

/* modal */
/* page content */
/* footer */
footer {
  font-size: 16px;
  color: #cdcdcd; }
  footer .middle {
    background: url(../img/img-11.jpg) repeat; }
  footer a {
    color: #cecece; }
    footer a:focus {
      color: #cecece; }
    footer a:hover {
      color: #cecece; }
  footer h2, footer h3, footer h4, footer h5, footer h6 {
    font-weight: normal;
    text-transform: inherit;
    line-height: 1;
    margin: 0; }
  footer h4 {
    font-family: "Conv_DINPro";
    font-size: 16px;
    color: #cdcdcd;
    text-transform: inherit; }
  footer ul {
    list-style: none;
    margin: 0;
    padding: 0; }
  footer .container {
    display: flex;
    justify-content: space-between; }
  footer .col--time:after {
    top: 2px; }
  footer .col--phone:after {
    top: 1px; }
  footer .footer__nav {
    background: url(../img/img-41.jpg) repeat;
    padding: 0 40px; }
    footer .footer__nav ul {
      display: flex;
      width: 100%;
      justify-content: space-between; }
      footer .footer__nav ul li {
        display: flex;
        height: 70px;
        align-items: center; }
        footer .footer__nav ul li h3 {
          font-size: 16px;
          font-family: "Conv_DINPro-Medium"; }
        footer .footer__nav ul li a {
          font-family: "Conv_DINPro-Medium";
          font-size: 16px;
          color: #171717;
          text-transform: uppercase;
          text-align: center;
          padding: 10px 0;
          border-bottom: 2px solid transparent; }
          footer .footer__nav ul li a:hover {
            border-bottom: 2px solid #c39570; }
        footer .footer__nav ul li.active a {
          border-bottom: 2px solid #c39570; }
  footer .middle {
    padding: 55px 0 45px; }
    footer .middle .container {
      flex-direction: column; }
    footer .middle .middle__up {
      display: flex;
      height: 130px;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      position: relative;
      margin-bottom: 45px; }
      footer .middle .middle__up .f--logo {
        display: block;
        width: 185px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 0 auto; }
        footer .middle .middle__up .f--logo h2 {
          margin: 0;
          line-height: 1; }
        footer .middle .middle__up .f--logo img {
          width: 100%; }
    footer .middle .middle__down {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start; }
  footer .down {
    background-color: #1e1e1e;
    font-size: 14px;
    color: #8f8f8f; }
    footer .down a {
      color: #8f8f8f; }
      footer .down a:focus {
        color: #8f8f8f; }
    footer .down .container {
      display: flex;
      height: 60px;
      align-items: center; }
    footer .down p {
      margin: 0; }
    footer .down .artmedia h6 {
      display: flex;
      align-items: center; }
    footer .down .artmedia img {
      margin-left: 7px; }
    footer .down h5, footer .down h6 {
      font-family: "Conv_DINPro-Medium";
      font-size: 14px;
      color: #8f8f8f;
      text-transform: inherit; }
  @media screen and (max-width: 768px) {
    footer .middle {
      padding: 20px 0; }
      footer .middle .middle__up {
        height: auto;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px; }
        footer .middle .middle__up .socials {
          width: 100%;
          order: 3;
          justify-content: center;
          margin-top: 15px; }
          footer .middle .middle__up .socials li {
            margin: 0 10px; }
        footer .middle .middle__up .btn {
          order: 2; }
        footer .middle .middle__up .f--logo {
          display: flex;
          width: 100%;
          order: 1;
          position: relative;
          top: 0;
          justify-content: center;
          margin: 0 0 15px; }
          footer .middle .middle__up .f--logo img {
            max-width: 100%;
            width: auto; }
    footer .footer__nav {
      padding: 0px; }
      footer .footer__nav ul li a {
        font-size: 14px;
        padding: 5px 0; } }
  @media screen and (max-width: 600px) {
    footer .middle {
      padding-bottom: 10px; }
      footer .middle .middle__down {
        justify-content: flex-start; }
        footer .middle .middle__down .footer__item {
          width: 100%;
          margin-bottom: 15px; }
    footer .down .container {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: auto;
      padding: 15px; }
    footer .down .artmedia {
      margin-top: 5px; } }
  @media screen and (max-width: 480px) {
    footer .footer__nav {
      display: none; } }

/* footer */
