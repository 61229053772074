/* footer */
footer {
    font-size: 16px;
    color: #cdcdcd;
    .middle {
        background: url(../img/img-11.jpg) repeat;
    }
    a {
        color: #cecece;
        &:focus {
            color: #cecece;
        }
        &:hover {
            color: #cecece;
        }
    }
    h2, h3, h4, h5, h6 {
        font-weight: normal;
        text-transform: inherit;
        line-height: 1;
        margin: 0;
    }
    h4 {
        font-family: $regular;
        font-size: 16px;
        color: #cdcdcd;
        text-transform: inherit;
    }
    ul {
        @include ul-default;
    }
    .container {
        display: flex;
        justify-content: space-between;
    }
    .col--time {
        &:after {
            top: 2px;
        }
    }
    .col--phone {
        &:after {
            top: 1px;
        }
    }
    .footer__nav {
        background: url(../img/img-41.jpg) repeat;
        padding:0 40px;
        ul {
            display: flex;
            width: 100%;
            justify-content: space-between;
            li {
                display: flex;
                height: 70px;
                align-items: center;
                h3 {
                    font-size: 16px;
                    font-family: $medium;
                }
                a {
                    font-family: $medium;
                    font-size: 16px;
                    color: #171717;
                    text-transform: uppercase;
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 2px solid transparent;
                    &:hover {
                        border-bottom: 2px solid #c39570;
                    }
                }
                &.active a {
                    border-bottom: 2px solid #c39570;
                }
            }
        }
    }
    .middle {
        padding: 55px 0 45px;
        .container {
            flex-direction: column;
        }
        .middle__up {
            display: flex;
            height: 130px;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            position: relative;
            margin-bottom: 45px;
            .f--logo {
                display: block;
                width: 185px;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
                h2 {
                    margin: 0;
                    line-height: 1;
                }
                img {
                    width: 100%;
                }
            }
        }
        .middle__down {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
        }
    }
    .down {
        background-color: #1e1e1e;
        font-size: 14px;
        color: #8f8f8f;
        a {
            color: #8f8f8f;
            &:focus {
                color: #8f8f8f;
            }
        }
        .container {
            display: flex;
            height: 60px;
            align-items: center;
        }
        p {
            margin: 0;
        }
        .artmedia {
            h6 {
                display: flex;
                align-items: center;
            }
            img {
                margin-left: 7px;
            }
        }
        h5,h6 {
            font-family: $medium;
            font-size: 14px;
            color: #8f8f8f;
            text-transform: inherit;
        }
    }
    @media screen and (max-width: 768px) {
        .middle {
            padding: 20px 0;
            .middle__up {
                height: auto;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                margin-bottom: 30px;
                .socials {
                    width: 100%;
                    order: 3;
                    justify-content: center;
                    margin-top: 15px;
                    li {
                        margin: 0 10px;
                    }
                }
                .btn {
                    order: 2;
                }
                .f--logo {
                    display: flex;
                    width: 100%;
                    order: 1;
                    position: relative;
                    top: 0;
                    justify-content: center;
                    margin: 0 0 15px;
                    img {
                        max-width: 100%;
                        width: auto;
                    }
                }
            }
        }
        .footer__nav {
            padding: 0px;
            ul li {
                a {
                    font-size: 14px;
                    padding: 5px 0;
                }
            }
        }
    }
    @media screen and (max-width: 600px) {
        .middle {
            padding-bottom: 10px;
            .middle__down {
                justify-content: flex-start;
                .footer__item {
                    width: 100%;
                    margin-bottom: 15px;
                }
            }
        }
        .down {
            .container {
                flex-direction: column;
                justify-content: center;
                align-items: center;
                height: auto;
                padding: 15px;
            }
            .artmedia {
                margin-top: 5px;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .footer__nav {
            display: none;
        }
    }
        
}
/* footer */